export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
};

//  Returns null if a string is empty or undefined
export const concatStrings = (...strings) => {
    return strings.filter((s) => s).join(" ");
};

export const buildProfilePictureUrl = (picture) => {
    return `${process.env.REACT_APP_API_URL}/public/pictures/profiles/${picture}`;
};

export const buildPublicFileUrl = (filePath) => {
    return `${process.env.REACT_APP_API_URL}/public/${filePath}`;
};

export const buildDocUrl = (document) => {
    return `${process.env.REACT_APP_API_URL}/public/pt_docs/${document}`;
};

// return true if the target string contains the search string
// case insensitive
// if the search string is empty, return true
// trim the search string before searching
export const contains = (target, search) => {
    if (isEmpty(search)) {
        return true;
    }
    return target.toLowerCase().includes(search.trim().toLowerCase());
};

export const getPTDates = (pt) => {
    const eePt = JSON.parse(pt.entreprise_exterieure_PT);
    let dateDebEE = null;
    let dateFinEE = null;

    if (eePt.date_debut_operations_EE !== undefined) {
        dateDebEE = new Date(eePt.date_debut_operations_EE);
    }
    if (eePt.date_fin_operations_EE !== undefined) {
        dateFinEE = new Date(eePt.date_fin_operations_EE);
    }

    return { dateDebEE, dateFinEE };
};

// sort callback
// sort by the most recent date
export const sortPTByDate = (ptA, ptB) => {
    const { dateDebEE: dateDebEEA } = getPTDates(ptA);
    const { dateDebEE: dateDebEEB } = getPTDates(ptB);
    if (dateDebEEA === null) {
        return 1;
    }
    if (dateDebEEB === null) {
        return -1;
    }
    if (dateDebEEA === dateDebEEB) {
        return 0;
    }

    return dateDebEEB - dateDebEEA;
};

// sort callback
// sort by the most recent created date
export const sortPTByCreatedDate = (ptA, ptB) => {
    const dateA = new Date(ptA.created);
    const dateB = new Date(ptB.created);
    if (dateA === dateB) {
        return 0;
    }
    return dateB - dateA;
};

// sort callback
// sort entreprises by raison_sociale
// alphabetical order
export const sortEntreprisesByRaisonSociale = (entrepriseA, entrepriseB) => {
    if (entrepriseA.raison_sociale < entrepriseB.raison_sociale) {
        return -1;
    }
    if (entrepriseA.raison_sociale > entrepriseB.raison_sociale) {
        return 1;
    }
    return 0;
};

export const printPageStyle =
    "@page { size: auto;  margin: 20mm; }  @page :footer { display: none } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }";

export const ValidatorStatus = {
    eu: "EU",
    ee: "EE",
    es: "ES",
};

export const getValidatorStatus = (validatorKey) => {
    const validatorNumber = Number(validatorKey.slice(-1));
    if (validatorNumber <= 5) {
        return ValidatorStatus.eu;
    } else if (validatorNumber === 6) {
        return ValidatorStatus.ee;
    } else if (validatorNumber === 7) {
        return ValidatorStatus.es;
    } else {
        return ValidatorStatus.eu;
    }
};

export const filterInvalidValidatorsCallback = ([key, validator]) => {
    return validator && validator.prenom && validator.nom && validator.signe !== 1;
};

/**
 * Function to add base64 string image to a file object
 * @param {string} base64String
 * @param {string} filename
 */
export const base64StringtoFile = (base64String, filename) => {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};
