import {GET_CONTACTS} from "../actions/contacts.actions";

const initialState = {};

export default function contactsReducer( state = initialState, action) {

    switch (action.type) {
        case GET_CONTACTS:
            return action.payload;

        default:
            return state;
    }
}