import {GET_RECO, SET_AVANT, SET_MODEOPE, SET_ORGASEC} from "../actions/recommandation.actions";

const initialState = {};

export default function contactsReducer( state = initialState, action) {

    switch (action.type) {
        case GET_RECO:
            return action.payload;

        case SET_AVANT:
            return state.map((reco) => {
                if(reco.id_usine.toString() === action.payload.id.toString()) {
                    return {
                        ...reco,
                        avant_intervention: action.payload.text
                    }
                } else {
                    return reco
                }
            });

        case SET_MODEOPE:
            return state.map((reco) => {
                if(reco.id_usine.toString() === action.payload.id.toString()) {
                    return {
                        ...reco,
                        mode_operatoire: action.payload.text
                    }
                } else {
                    return reco
                }
            });

        case SET_ORGASEC:
            return state.map((reco) => {
                if(reco.id_usine.toString() === action.payload.id.toString()) {
                    return {
                        ...reco,
                        orga_secours : JSON.stringify(action.payload.orga)
                    }
                } else {
                    return reco
                }
            })


        default:
            return state;
    }
}