import React, { useState } from "react";
import "./NavbarAdmin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { NavLink, Link } from "react-router-dom";
import MobileBurgerTrigger from "../../Ui/MobileBurgerTrigger/MobileBurgerTrigger";

const NavbarAdmin = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openMenu = (isOpen) => {
        setMobileMenuOpen(isOpen);
    };

    return (
        <>
            <MobileBurgerTrigger onOpenClose={openMenu} />

            <div
                className={`aside-menu ${mobileMenuOpen ? "mobile_menu_open" : ""}`}
                style={{ backgroundColor: "black" }}
            >
                <div className="top_navbar">
                    <Link to="/accueil">
                        <button className="nav-link">
                            Accueil
                            <FontAwesomeIcon icon={faHome} />
                        </button>
                    </Link>
                </div>
                <div className="reglage_navbar">
                    <div className="text_navbar">Réglages Admin</div>
                    <NavLink end to="/utilisateurs" className="nav-link">
                        Utilisateurs
                    </NavLink>
                    <NavLink end to="/recommandations-admin" className="nav-link inline-block-nav">
                        Recommandations EE
                    </NavLink>
                    <NavLink end to="/analyse-risque-admin" className="nav-link">
                        Analyse de risque
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default NavbarAdmin;
