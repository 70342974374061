import React, { useState } from "react";
import "./MobileBurgerTrigger.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

export default function MobileBurgerTrigger({ onOpenClose }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openMenu = () => {
        onOpenClose(!mobileMenuOpen);
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <>
            <div className="mobile-burger-container">
                <div className="mobile-burger-button" onClick={openMenu}>
                    <FontAwesomeIcon icon={mobileMenuOpen ? faClose : faBars} size="lg" color="white" />
                    <span className="mobile-burger-button-text" style={{ color: "white", paddingLeft: "8px" }}>
                        {mobileMenuOpen ? "Fermer" : "Menu"}
                    </span>
                </div>
            </div>
        </>
    );
}
