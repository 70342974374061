import React from "react";
import "./PDFHeader.css";
import headerPDF from "../assets/img/header.jpg";
import { QRCodeCanvas } from "qrcode.react";

function PDFHeader(props) {
    console.log(`${process.env.REACT_APP_HOST_NAME}/login-ee/${props.ptId}`);
    return (
        <div className="PDFHeaderPosition">
            <div className="imgHeader">
                <img alt="" style={{ width: "100vw" }} src={headerPDF} />
            </div>

            <div className="PDFHeader">
                <div className="LeftHeader">
                    Rédacteur du PT : <br />
                    {props.prenom} {props.nom}
                    <h2 style={{ marginLeft: "20px" }}>
                        PT N° : {props.numPermis}
                    </h2>
                </div>
                <div className="RightHeader">
                    <QRCodeCanvas
                        value={`${process.env.REACT_APP_HOST_NAME}/login-ee/${props.ptId}`}
                        size={80}
                    />
                </div>
            </div>
        </div>
    );
}
export default PDFHeader;
