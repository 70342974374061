import React from 'react';

const CardCommentaire = ({commentaire}) => {
    return (
        <div style={{marginBottom: '15px'}}>
            {commentaire.date}<br/>
            {commentaire.texte}
        </div>
    );
};

export default CardCommentaire;