import React, { Component, useEffect, useState } from "react";
import "./FinChantier.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import ImprimerIntervenants from "../HeaderNewPT/BoutonsHeader/Validateur/Imprimer/ImprimerIntervenants";
import ChecklistFC from "./ChecklistFC/ChecklistFC";
import AddCommentFC from "../HeaderNewPT/BoutonsHeader/AddComment/AddCommentFC";
import { useParams } from "react-router-dom";
import CardVSNPT from "../Validation/VisaNonSigne/CardVSN/CardVSN";
import CardVS from "../Validation/VisaSigne/CardVS/CardVS";
import { useDispatch, useSelector } from "react-redux";
import { getPt } from "../../../../Redux/actions/pt.actions";
import userReducer from "../../../../Redux/reducers/user.reducer";
import { isEmpty } from "../../../../Utils/utils";
import CardCommentaire from "./CardCommentaire";
import ImprimerSignataireFin from "../HeaderNewPT/BoutonsHeader/Validateur/Imprimer/ImprimerSignataireFin";
import axios from "axios";
import NewPTContent from "../NewPTContent";
import { usePrompt } from "../../../../hooks/usePrompt";

const FinChantier = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [pt, setPt] = useState({});
    const [signataire, setSignataire] = useState({});
    const [commentaires, setCommentaires] = useState({});
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    const fetchPt = async () => {
        if (userReducer.id !== undefined) {
            const id_usine = userReducer.id_hash_usine;
            await dispatch(getPt(id_usine));
            setLoading(false);
        }
    };

    const handleCloture = async () => {
        if (window.confirm("Confirmez-vous vouloir clôturer ce PT ?")) {
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/cloture/${id}`,
                withCredentials: true,
            })
                .then(async () => {
                    alert("Cloture effectuée, vous pourrez quand même continuer à signer le PT");
                    await dispatch(getPt(userReducer.id_hash_usine));
                })
                .catch((err) => {
                    console.log(err);
                    alert("Erreur dans la clôture du pt");
                });
        }
    };

    const setValid = () => {
        if (!isEmpty(ptReducer[0])) {
            ptReducer[0].map((thePt) => {
                if (thePt.id.toString() === id) {
                    setPt(thePt);
                    const tmp = JSON.parse(thePt.signataires_fin);
                    setCommentaires(JSON.parse(thePt.commentaires));
                    let array = [];
                    for (const key in tmp) {
                        array.push(tmp[key]);
                    }
                    setSignataire(tmp);
                }
            });
        }
        setDone(true);
    };

    const handleSign = (newValidators) => {
        setSignataire(newValidators);
    };

    useEffect(() => {
        if (loading) {
            fetchPt();
        } else {
            if (!done) {
                setValid();
            }
        }
    }, [ptReducer, loading, done, userReducer]);

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Fin de Chantier" />
            <ImprimerSignataireFin pt={pt} validateurs={signataire} />
            <AddCommentFC pt={pt} />

            <NewPTContent>
                {done && <ChecklistFC pt={pt} />}

                <div>
                    <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                        COMMENTAIRES
                    </div>

                    <div style={{ color: "grey" }}>
                        {done &&
                            commentaires !== null &&
                            Object.keys(commentaires).map((k) => {
                                return <CardCommentaire commentaire={commentaires[k]} />;
                            })}
                    </div>
                </div>

                <div className="titre_vnspt">VISA À SIGNER</div>
                <div className="display_vnspt">
                    {!loading &&
                        done &&
                        Object.entries(signataire).map(([key, valid]) => {
                            if (valid.prenom !== "" && valid.nom !== "" && valid.signe === 0) {
                                return (
                                    <CardVSNPT valid={valid} pt={pt} fin={true} validKey={key} onSign={handleSign} />
                                );
                            }
                        })}
                </div>
                <div className="titre_vnspt">VISA SIGNÉ</div>
                <div className="display_vspt">
                    {!loading &&
                        done &&
                        Object.entries(signataire).map(([key, valid]) => {
                            if (valid.prenom !== "" && valid.nom !== "" && valid.signe === 1) {
                                return <CardVS valid={valid} />;
                            }
                        })}
                </div>
                <div className="content_vspt">
                    {!loading && done && pt.status !== "clotures" && (
                        <>
                            <div
                                className="bandeauValidationI d-flex justify-content-between align-items-center"
                                style={{ position: "relative" }}
                            >
                                <div>
                                    <h5>CLOTURER LE PERMIS DE TRAVAIL</h5>
                                    <span>Attention, cette étape est irréversible</span>
                                </div>
                                <div>
                                    <button className="buttonValidationI btn btn-success" onClick={handleCloture}>
                                        Clôturer le PT
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </NewPTContent>
        </div>
    );
};

export default FinChantier;
