export const restrictedRiskAnalysis = {
    //BADOIT
    badoit: [
        "Permis de travail spécifique hors toiture : FO 1918 élaboré par  DO",
        "Permis fouille et excavations : FO 1917 elaboré par DO",
        "Permis de travail spécifique sur toiture : FO 1659 élaboré par  DO",
        "Permis de travail specifique en espaces confinés : FO 1631 élaboré par DO",
        "Pour toute intervention sur la Haute Tension utilisation du permis FO 1658",
        "Remplir systématiquement la fiche de prêt de matériel entre entreprise (en annexe du FO 1211). Si prêt de matériels, conformes, vérifiés! Qui est formé pour le faire?",
    ],

    // SALVETAT
    salvetat: [
        "Permis de travail spécifique hors toiture : élaboré par  DO",
        "Permis fouille et excavations : élaboré par DO",
        "Permis de travail spécifique sur toiture : élaboré par  DO",
        "Permis de travail spécifique sur toiture : élaboré par  DO",
        "Permis de travail specifique en espaces confinés : élaboré par DO",
        "Pour toute intervention sur la Haute Tension utilisation du permis consignation deconsignation",
        "Remplir systématiquement la fiche de prêt de matériel entre entreprise. Si prêt de matériels, conformes, vérifiés! Qui est formé pour le faire?",
    ],

    //EVIAN
    evian: [
        "Permis de travail spécifique hors toiture de bl OMEGA élaboré par  DO",
        "Permis fouille et excavations de bl OMEGA élaboré par  DO",
        "Permis de travail spécifique sur toiture de bl OMEGA élaboré par  DO",
        "Permis de travail specifique en espaces confinés de bl OMEGA élaboré par  DO",
        "Pour toute intervention sur la Haute Tension utilisation du permis spécifique de bl OMEGA élaboré par  DO",
        "Permis spécifique de Manutention de bl OMEGA élaboré par  DO",
        "Permis de travail spécifique sur réseaux de bl OMEGA élaboré par  DO",
      ],

    //VOLVIC
    volvic: [
        "Permis de travail spécifique hors toiture",
        "Permis fouille et excavations",
        "Permis de travail spécifique sur toiture",
        "Permis de travail specifique en espaces confinés",
        "Pour toute intervention sur la Haute Tension utilisation du permis spécifique",
    ],
};
