import React, { useState } from "react";
import "./NavbarReglage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { NavLink, Link } from "react-router-dom";
import MobileBurgerTrigger from "../../Ui/MobileBurgerTrigger/MobileBurgerTrigger";

const NavbarReglage = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openMenu = (isOpen) => {
        setMobileMenuOpen(isOpen);
    };

    return (
        <>
            <MobileBurgerTrigger onOpenClose={openMenu} />
            <div
                className={`aside-menu ${mobileMenuOpen ? "mobile_menu_open" : ""}`}
                style={{ backgroundColor: "#3f3f3f" }}
            >
                <div className="top_navbar">
                    <Link to="/accueil">
                        <button className="nav-link">
                            Accueil
                            <FontAwesomeIcon icon={faHome} />
                        </button>
                    </Link>
                </div>
                <div className="reglage_navbar">
                    <div className="text_navbar">Réglages du compte</div>
                    <NavLink end to="/reglage" className="nav-link">
                        Général
                    </NavLink>
                    <NavLink end to="/kpir" className="nav-link">
                        KPIs
                    </NavLink>
                    <NavLink end to="/help" className="nav-link">
                        Aide
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default NavbarReglage;
