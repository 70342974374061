import React, { useState } from "react";
import "./Description.css";
import Navbar from "../../NavBar/Navbar";
import CreateDesc from "./CreateDesc/CreateDesc";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import { useParams } from "react-router-dom";
import NewPTContent from "../NewPTContent";

import { usePrompt } from "../../../../hooks/usePrompt";

const DescriptionNewPT = () => {
    const { id } = useParams();
    const [isDirty, setIsDirty] = useState(false);

    usePrompt(
        "Vous avez effecté des modifications sur cette page. Voulez-vous vraiment quitter sans sauvegarder ?",
        isDirty
    );

    return (
        <>
            <div>
                <Navbar isDirty={isDirty} />
                <NavbarNewPT id={id} isDirty={isDirty} />
                <HeaderNewPT titre="Description du travail" />
                <NewPTContent>
                    <CreateDesc isDirty={isDirty} setIsDirty={setIsDirty} />
                </NewPTContent>
            </div>
        </>
    );
};

export default DescriptionNewPT;
