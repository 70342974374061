import React, { useEffect, useRef, useState } from "react";
import "./EnvoiMail.css";

const EnvoiMail = ({ pt, show }) => {
    const showSend = () => {
        show(pt);
    };

    return (
        <div>
            <button style={{ color: "#212529", padding: 0 }} onClick={showSend}>
                Envoyer par mail
            </button>
        </div>
    );
};

export default EnvoiMail;
