import React, { useRef } from "react";
import "./Header.css";
import Filtre from "../Filtre/Filtre";
import ImprimerVierge from "./ImprimerVierge/ImprimerVierge";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header(props) {
    const filterRef = useRef();
    const handleReset = () => {
        filterRef.current.handleReset();
    };

    return (
        <div className="header_container">
            <div className="header_pt">
                <div className="titre_page">
                    <h1>{props.titre}</h1>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="bouton_header">
                        <ImprimerVierge />
                    </div>
                    <button onClick={handleReset} className="bouton_pt">
                        <FontAwesomeIcon icon={faBars} />
                        Filtrer
                    </button>
                </div>
            </div>
            <Filtre
                ref={filterRef}
                handleChangeNumFiltre={props.handleChangeNum}
                handleChangeTitreFiltre={props.handleChangeTitre}
                handleChangeEEFiltre={props.handleChangeEE}
                handleChangeDateDebFiltre={props.handleChangeDateDeb}
                handleChangeDateFinFiltre={props.handleChangeDateFin}
                handleChangeRdpFiltre={props.handleChangeRdp}
            />
        </div>
    );
}

export default Header;
