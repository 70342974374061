import axios from "axios";

export function getCurrentUsersByHabilitation(habilitation) {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/users/by-habilitation`,
        withCredentials: true,
        data: {
            habilitation,
        },
    });
}

export function getIntervenantsRequest() {
    return axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/pt/pointages`,
        withCredentials: true,
    });
}
