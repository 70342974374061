import axios from 'axios';

export const GET_USINES = "GET_USINES";

export const getUsines = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/usines`,
            withCredentials: true
        })
            .then((res) => {

                dispatch({type : GET_USINES, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}