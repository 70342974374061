import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function ModifierDate({handleChangeDate, showUpdateDate}) {
    const [modal, setModal] = useState(false);
    const [date, setDate] = useState(new Date().getDate.toString());

    const toggleModalDate = () => { 
        setModal(!modal);
        showUpdateDate()
    };

    const handleChange = () => {
        handleChangeDate(date);
        showUpdateDate()
        setModal(!modal);
    }

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }
    
    return(
        <div>
            <button onClick={toggleModalDate} className="boutonIntervenant" style={{
                                zIndex: 1,
                                right: "31px",
                                position: "relative"
                            }}>
                Modifier la date
            </button>


            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Modifier la date
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalDate}
                                >
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <div style={{paddingBottom: '100px'}}><Calendar onChange={setDate}/></div>

                                <button
                                    type="button"
                                    className="modal_button_imprimer_validateur"
                                    onClick={handleChange}
                                >
                                    Valider la selection
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            )}
        </div>
    )
}

export default ModifierDate;