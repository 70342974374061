import React, {useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import cookie from "js-cookie";
import axios from "axios";


function Deconnexion() {

    const removeCookie = (key) => {cookie.remove(key, {expires:1})};
    const logout = async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/logout`,
            withCredentials: true
        })
            .then(() => removeCookie('jwt'))
            .catch((err) => console.log(err));
        window.location = "/";

    }


    return (
        <>
            <button
                className="deconnexion"
                onClick={logout}
                style={{
                    border: 'none',
                    color: 'white',
                    backgroundColor: '#072872'
                }}
            >
                <FontAwesomeIcon icon={ faLock } />
            </button>
        </>
    );
}
    


export default Deconnexion;