import React, { useRef, useState, useContext, useEffect } from "react";
import "./Login.css";
import AuthContext from "../Context/AuthProvider";
import LeftLogin from "./DesignLogin/LeftLogin";
import { Buffer } from "buffer";
import { sha512 } from "js-sha512";
import logoTop from "../../assets/images/logo/logowhite2.png";
import logoBot from "../../assets/images/logo/logowhite1.png";
import axios from "axios";
import { UidContext } from "../Context/UidContext";
import { useDispatch } from "react-redux";
import { getUser } from "../../Redux/actions/user.actions";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {FailedLoginModal} from './FailedLoginModal';

function Login({ accepted ,refused}) {
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const Uid = useContext(UidContext);

    const dispatch = useDispatch();

    useEffect(() => {
        if (Uid) {
            navigate("/accueil");
        }
    }, [Uid]);

    const encodePass = () => {
        const dataSha512 = sha512("qs" + password + "38FJEu");
        let buff = new Buffer.from(dataSha512);
        let passwordEncode = buff.toString("base64");
        return passwordEncode;
    };

    const handleConnexion = (e) => {
        e.preventDefault();

        const passwordEncode = encodePass();

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/login`,
            withCredentials: true,
            data: {
                mail,
                passwordEncode,
            },
        })
            .then((res,reply) => {
                if (res.data) {
                    console.log(res.data);
                    dispatch(getUser(res.data));
                    window.location = "/accueil";
                    
                } else {
                    setErrMsg("Login ou mot de passe incorrect");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div className="d-flex" style={{ display: "flex" }}>
                <LeftLogin className="desktop-only" />

                {success ? (
                    <section>
                        <h1>You are logged in!</h1>
                    </section>
                ) : (
                    <section className="login-container">
                        <div className="d-flex flex-column login-logo-container">
                            <img src={logoTop} alt="logo" />
                            <img src={logoBot} alt="logo" />
                        </div>
                        <h1>Connectez-vous</h1>
                        <form onSubmit={handleConnexion} className="d-flex flex-column login-form-container">
                            <input
                                placeholder="Utilisateur"
                                type="text"
                                id="mail"
                                autoComplete="off"
                                onChange={(e) => setMail(e.target.value)}
                                value={mail}
                                required
                                style={{ height: "50px", marginBottom: "8px", borderRadius: "5px", width: "100%" }}
                            />

                            <input
                                style={{ height: "50px", marginBottom: "8px", borderRadius: "5px", width: "100%" }}
                                placeholder="Mot de passe"
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                            <button
                                style={{
                                    height: "50px",
                                    backgroundColor: "#1d5399",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                }}
                                disabled={accepted ? false : true}
                            >
                                Connexion
                            </button>
                            <NavLink
                                style={{
                                    width: "100%",
                                    borderRadius: "5px",

                                    height: "30px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                end
                                to="/forget-password"
                                className="nav-link"
                            >
                                Mot de passe oublié
                            </NavLink>
                        </form>
                        <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                            {errMsg}
                        </p>
                    </section>
                    
                )}   
                <FailedLoginModal refused={refused}/>
            </div>
        </>
    );
}

export default Login;
