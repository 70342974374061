import React from "react";
import './Login.css';
import LeftLogin from "./DesignLogin/LeftLogin";
import logoTop from "../../assets/images/logo/logowhite2.png";
import logoBot from "../../assets/images/logo/logowhite1.png";

function Error404() {


    return (
        <>
            <div className="d-flex">
                <LeftLogin />
                
                
                <section style={{
                    backgroundColor: '#072872',
                    width: '50%',
                    color: 'white',
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div 
                            className="d-flex flex-column"
                            style={{
                                position: 'fixed',
                                top: '100px',
                                left: '55%'
                            }}> 
                            <img src={logoTop} alt="logo"  />
                            <img src={logoBot} alt="logo"  />
                        </div>
                        <h1>Erreur 404</h1>
                </section>
            </div>
            
        </>
    );
}

export default Error404;