import React from "react";
import "./ComponentToPrint.css";
import PDFHeader from "../PDFHeader/PDFHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";

// Using a class component, everything works without issue
export class ComponentToPrint extends React.PureComponent {


    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader
                    numPermis={this.props.pt.numero_PT}
                    prenom={this.props.eu.contact_RdP.prenomRdP}
                    nom={this.props.eu.contact_RdP.nomRdP}
                />

                <h1 style={{textAlign: 'center', marginTop: '80px', marginBottom: '20px'}}>Liste des intervenants :</h1>


                <div className="ArrayValidateur">
                    <table className="table table-bordered table-sm align-middle" style={{width: '90%', marginLeft: '5%'}}>
                        <thead style={{backgroundColor: '#4aabe7', color: 'white'}}>
                        <tr>
                            <th scope="col">Intervenant</th>
                            <th scope="col">Date</th>
                            <th scope="col">Signature</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(this.props.intervenants).map((inter) => {
                            return <tr className="PDFTableTR">
                                <th className="PDFTableTH" scope="row">{this.props.contacts.map((contact) => {
                                    if(contact.id.toString() === this.props.intervenants[inter].id_contact.toString()) {
                                        return contact.prenom + ' ' + contact.nom;
                                    }
                                })}</th>
                                <td className="PDFTableTD">{this.props.date}</td>
                                <td className="PDFTableTD">Arrivée : {this.props.intervenants[inter].pointage_jour !== null ? Object.keys(JSON.parse(this.props.intervenants[inter].pointage_jour)).map((pointage) => {
                                    if(JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].jour === this.props.date) {
                                        return JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].arrivee;
                                    }
                                }) : ''} <br/>
                                    Pause : {this.props.intervenants[inter].pointage_jour !== null ? Object.keys(JSON.parse(this.props.intervenants[inter].pointage_jour)).map((pointage) => {
                                        if(JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].jour === this.props.date) {
                                            return JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].pause;
                                        }
                                    }) : ''}<br/>
                                    Depart : {this.props.intervenants[inter].pointage_jour !== null ? Object.keys(JSON.parse(this.props.intervenants[inter].pointage_jour)).map((pointage) => {
                                        if(JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].jour === this.props.date) {
                                            return JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].depart;
                                        }
                                    }) : ''}<br/>
                                    Total : {this.props.intervenants[inter].pointage_jour !== null ? Object.keys(JSON.parse(this.props.intervenants[inter].pointage_jour)).map((pointage) => {
                                        if(JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].jour === this.props.date) {
                                            return JSON.parse(this.props.intervenants[inter].pointage_jour)[pointage].total;
                                        }
                                    }) : ''}

                                </td>
                            </tr>
                        })
                        }
                        </tbody>
                    </table>

                </div>
                <p style={{marginLeft : '80%', marginBottom: '10px'}}>Signez ici</p>
                <div style={{borderColor :'#7ECAC8', borderStyle : 'solid', borderWidth: '1px', width: '75px', height: '75px', marginLeft : '80%', display : "flex", alignItems : "center", justifyContent : "center", color : "white"}}>/sn1/</div>
            </div>
        );
    }
}
