import React, { useCallback, useEffect, useState } from "react";
import "./AnalyseRisque.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import BoutonAR from "./BoutonAR/BoutonAR";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRisques, updateAnalyseRisque, updateModeOpeCom } from "../../../../Redux/actions/risques.actions";
import { isEmpty } from "../../../../Utils/utils";
import { getPt } from "../../../../Redux/actions/pt.actions";
import { getCurrentRisques, getRisquesByPT } from "../../../../Utils/api/risques.api";
import NewPTContent from "../NewPTContent";
import { usePrompt } from "../../../../hooks/usePrompt";

const defaultModeOpeCom = {
    mode_ope: false,
    acte_valid: false,
    veille_ely: false,
    commentaire: "",
};

const AnalyseRisque = () => {
    const { id } = useParams();

    const [data, setData] = useState({});
    const [done, setDone] = useState(false);
    const [modOpComInfos, setModOpComInfos] = useState(defaultModeOpeCom);

    const [loading, setLoading] = useState(true);

    const userReducer = useSelector((state) => state.userReducer);

    const ptReducer = useSelector((state) => [state.ptReducer]);
    const [pt, setPt] = useState({});

    const [risques, setRisques] = useState([]);

    const [ptFetched, setPtFetched] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    usePrompt(
        "Vous avez effecté des modifications sur cette page. Voulez-vous vraiment quitter sans sauvegarder ?",
        isDirty
    );

    const dispatch = useDispatch();

    const fetchRisques = useCallback(
        async (id) => {
            if (userReducer.id !== undefined) {
                let request = () => {};
                console.log("ID", id);
                if (id == 0 || id === undefined) {
                    request = () => getCurrentRisques();
                }
                if (!isEmpty(pt)) {
                    request = () => getRisquesByPT(pt.id);
                }
                request()
                    .then((res) => {
                        setRisques(res.data);
                        console.log(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [userReducer.id, pt]
    );

    const fetchPT = useCallback(async () => {
        const id_usine = userReducer.id_hash_usine;
        await dispatch(getPt(id_usine));
        setPtFetched(true);
    }, [dispatch, userReducer.id_hash_usine]);

    const setRisquesKeys = useCallback(
        async (id) => {
            let array = [];
            const newObject = {};
            if (!isEmpty(risques[0])) {
                risques.map((risque) => {
                    if (id == 0 || id === undefined || (!isEmpty(risque.id_pt) && risque.id_pt.toString() === id)) {
                        array.push(risque.titre_risque);
                        newObject[risque.titre_risque] = JSON.parse(risque.mesures_prevention);
                    }
                });

                setData(newObject);
            }
        },
        [risques]
    );

    const handleChangeData = (key, value) => {
        setIsDirty(true);
        const newObject = { ...data };
        newObject[key] = value;
        setData(newObject);
    };

    const handleChangeModeOpe = (e) => {
        setIsDirty(true);
        let tmp = modOpComInfos;
        tmp.mode_ope = e.target.checked;
        console.log(tmp);
        setModOpComInfos(tmp);
    };

    const handleChangeActe = (e) => {
        setIsDirty(true);
        let tmp = modOpComInfos;
        tmp.acte_valid = e.target.checked;
        setModOpComInfos(tmp);
    };

    const handleChangeVeilleELYFEC = (e) => {
        setIsDirty(true);
        setIsDirty(true);
        let tmp = modOpComInfos;
        tmp.veille_ely = e.target.checked;
        setModOpComInfos(tmp);
    };

    const handleChangeCom = (e) => {
        setIsDirty(true);
        let tmp = modOpComInfos;
        tmp.commentaire = e.target.value;
        setModOpComInfos(tmp);
    };

    useEffect(() => {
        setRisquesKeys(id);
    }, [id, setRisquesKeys, risques]);

    useEffect(() => {
        if (!isEmpty(ptReducer[0]) && !ptFetched && id !== undefined) {
            const currentPT = ptReducer[0].find((pt) => pt.id.toString() === id);
            if (currentPT) {
                setPt(currentPT);
                const parsed = JSON.parse(currentPT.risque_mode_ope_com);
                if (parsed) {
                    setModOpComInfos(parsed);
                }
                setDone(true);
            }
        }
        if (id == 0) {
            setDone(true);
        }
    }, [id, ptReducer, ptFetched]);

    useEffect(() => {
        if (userReducer.id !== undefined) {
            fetchPT();
            fetchRisques();
            setLoading(false);
        }
    }, [userReducer, fetchPT, fetchRisques]);

    const submit = async () => {
        if (pt.validation_ec === 0) {
            try {
                setIsDirty(false);
                await dispatch(updateAnalyseRisque(id, data));
                await dispatch(updateModeOpeCom(id, modOpComInfos));
                await fetchRisques();
                alert("Analyse des risques mise à jour");
            } catch (err) {
                console.log(err);
                setIsDirty(true);
                alert("Erreur dans la sauvegarde");
            }
        } else {
            alert(
                "Le PT a commencé à être signé, impossible de modifier autre chose que le mode opératoire et le commentaire. Ces modifications ont été sauvegardées"
            );
            setIsDirty(false);
            await dispatch(updateModeOpeCom(id, modOpComInfos));
            await fetchRisques();
        }
    };

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Analyse de risque" />
            <NewPTContent className="content_ARnewpt">
                <div className="name_newpt" style={{ marginBottom: "20px" }}>
                    CHECKLIST
                </div>
                <div className="checkbox_mode_operatoire" style={{ marginBottom: "40px" }}>
                    <form>
                        <div style={{ display: "flex" }}>
                            {!loading && done && (
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px", minWidth: "16px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={modOpComInfos.mode_ope}
                                    onChange={(e) => handleChangeModeOpe(e)}
                                    disabled={id !== "0" && pt.status === "clotures" || pt.validation_ec === 1? true : false}
                                />
                            )}
                            <label htmlFor="checkbox">Mode opératoire fourni par l'EE</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            {!loading && done && (
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px", minWidth: "16px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={modOpComInfos.acte_valid}
                                    onChange={(e) => handleChangeActe(e)}
                                    disabled={id !== "0" && pt.status === "clotures" || pt.validation_ec === 1? true : false}
                                />
                            )}
                            <label htmlFor="checkbox">Acte de sous-traitance signé et renvoyé à l'EE</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            {!loading && done && (
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px", minWidth: "16px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={modOpComInfos.veille_ely}
                                    onChange={(e) => handleChangeVeilleELYFEC(e)}
                                    disabled={id !== "0" && pt.status === "clotures" || pt.validation_ec === 1? true : false}
                                />
                            )}
                            <label htmlFor="checkbox">Veille réglementaire effectuée sur la plateforme ELYFEC</label>
                        </div>
                    </form>
                </div>

                <>
                    {!loading && !isEmpty(risques)
                        ? risques.map((risque) => {
                              if (!isEmpty(risque.id_pt) && risque.id_pt.toString() === id) {
                                  return (
                                      <BoutonAR
                                          key={risque.id}
                                          titreAR={risque.titre_risque}
                                          mesure_prevention={risque.mesures_prevention}
                                          handleSetData={handleChangeData}
                                          descriptionAR={risque.description_risque}
                                          disabled={
                                              id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                  ? true
                                                  : false
                                          }
                                      />
                                  );
                              }
                          })
                        : null}
                </>

                {!loading && done && (
                    <textarea
                        placeholder="Recommandations complémentaires"
                        className="commentAR"
                        defaultValue={modOpComInfos.commentaire}
                        onChange={(e) => handleChangeCom(e)}
                        disabled={id !== "0" && pt.status === "clotures" ? true : false}
                    ></textarea>
                )}

                <button
                    className="button_add_do"
                    onClick={submit}
                    style={{
                        width: "60%",
                        height: "50px",
                        backgroundColor: "#4aabe7",
                        color: "#fff",
                        border: "2px solid #4aabe7",
                        marginBottom: "30px",
                        borderRadius: "5px",
                    }}
                >
                    Sauvegarder les modifications
                </button>
            </NewPTContent>
        </div>
    );
};

export default AnalyseRisque;
