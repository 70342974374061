import React, { useEffect, useState } from "react";
import FavorisBouton from "./BoutonFavoris/BoutonFavoris";
import MenuContextuel from "./MenuContextuel/MenuContextuel";

const CardPt = ({ pt, send }) => {
    const [ee, setEE] = useState({});
    const [eu, setEU] = useState({});
    const [euCon, setEUCon] = useState({});
    const [eeCon, setEECon] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pt) {
            if (loading) {
                setEE(JSON.parse(pt.entreprise_exterieure_PT));
                setEECon(JSON.parse(pt.entreprise_exterieure_PT_contacts));
                setEU(JSON.parse(pt.entreprise_utilisatrice_PT));
                setLoading(false);
            }
        }
    }, [loading]);
    return (
        <div className="tableau_content" key={pt.id}>
            {!loading && (
                <table className="table table-borderless">
                    <tbody>
                        <tr className="tableau_content">
                            <th style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <FavorisBouton pt={pt} />
                            </th>
                            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {pt.numero_PT}
                            </td>
                            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {pt.nom_PT}
                                <br />
                                {ee !== null ? ee.nom_EE_PT : ""}
                            </td>
                            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {ee !== null ? ee.date_debut_operations_EE : ""}
                                <br />
                                {ee !== null ? ee.date_fin_operations_EE : ""}
                            </td>
                            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {eu !== null ? eu.contact_RdP.prenomRdP : ""}
                            </td>
                            <td>
                                <MenuContextuel pt={pt} showSend={send} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default CardPt;
