import React, { useState } from "react";
import "./SignatureModal.css";
import SignatureCanvas from "react-signature-canvas";
import { base64StringtoFile } from "../../../Utils/utils";

export default function SignatureModal({ isOpen, onClose, title, validator, onValidate }) {
    const canvasRef = React.useRef(null);
    const fileInputRef = React.useRef(null);

    const [isEmpty, setIsEmpty] = useState(true);
    const [showMessage, setShowMessage] = useState(true);
    const [localImage, setLocalImage] = useState(null);

    const handleFileInput = (e) => {
        setLocalImage(e.target.files[0]);
        setIsEmpty(false);
    };

    const handleClear = () => {
        if (localImage !== null) {
            setLocalImage(null);
            setIsEmpty(true);
        } else {
            if (canvasRef.current) canvasRef.current.clear();
            updateEmpty();
        }
        setShowMessage(true);
    };

    const handleValidate = () => {
        if (localImage !== null) {
            onValidate(localImage);
        } else {
            const data = canvasRef.current.toDataURL("image/png");
            const signatureFile = base64StringtoFile(data, "signature.png");
            onValidate(signatureFile);
        }
    };

    const handleClose = () => {
        handleClear();
        onClose();
    };

    const updateEmpty = () => {
        setIsEmpty(canvasRef.current ? canvasRef.current.isEmpty() : true);
    };

    if (!isOpen) return null;

    return (
        <div className="signature-modal">
            <div className="signature-wrapper">
                <div className="signature-inside">
                    <div className="signature-header">
                        {title}
                        <button type="button" className="modal-close-button" onClick={handleClose}>
                            X
                        </button>
                    </div>
                    <div className="signature-content">
                        <h4 style={{ alignSelf: "start" }}>
                            {validator.prenom} {validator.nom}
                        </h4>
                        {localImage === null ? (
                            <div className="signature-canvas-container">
                                <SignatureCanvas
                                    ref={canvasRef}
                                    penColor="black"
                                    canvasProps={{ className: "signature-canvas" }}
                                    onEnd={updateEmpty}
                                    onBegin={() => {
                                        setShowMessage(false);
                                    }}
                                    clearOnResize={true}
                                />
                                {isEmpty && showMessage ? (
                                    <h4 className="signature-canvas-message">Veuillez signer dans ce cadre</h4>
                                ) : null}
                            </div>
                        ) : (
                            <img
                                src={URL.createObjectURL(localImage)}
                                alt="signature"
                                className="signature-image-preview"
                            />
                        )}
                        <input
                            type="file"
                            multiple={false}
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileInput}
                        />
                        <div className="signature-options-container">
                            <button
                                type="button"
                                onClick={() => {
                                    fileInputRef.current && fileInputRef.current.click();
                                }}
                            >
                                Ajouter une image
                            </button>
                            <button type="button" onClick={handleClear}>
                                Effacer
                            </button>
                        </div>
                        <div className="signature-buttons-container">
                            <button
                                type="button"
                                className="signature-button"
                                disabled={isEmpty}
                                onClick={handleValidate}
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
