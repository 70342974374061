import axios from "axios";

export const GET_RISQUEORIGIN = "GET_RISQUEORIGIN";
export const SET_RISQUEORIGINMP = "SET_RISQUEORIGINMP";
export const SET_RISQUEORIGINDESC = "SET_RISQUEORIGINDESC";
export const SET_RISQUEORIGINTITRE = "SET_RISQUEORIGINTITRE";
export const NEW_AR = "NEW_AR";
export const DELETE_AR = "DELETE_AR";

export const getRisqueOriginaux = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/risques-originaux`,
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: GET_RISQUEORIGIN, payload: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const setRisqueOriginauxMP = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/risques-origin-mp`,
            data: { id, data },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: SET_RISQUEORIGINMP, payload: { id, data } });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const setRisqueOriginauxDesc = (id, desc) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/risques-origin-desc`,
            data: { id, desc },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: SET_RISQUEORIGINDESC, payload: { id, desc } });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const setRisqueOriginauxTitre = (id, titre) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/risques-origin-titre`,
            data: { id, titre },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: SET_RISQUEORIGINTITRE, payload: { id, titre } });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const newAr = (idUsine, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/risques-origin-newar`,
            data: { idUsine, data },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: NEW_AR, payload: { idUsine, data } });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const deleteAR = (idUsine, titreAR, id) => {
    return (dispatch) => {
        return axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}/risques-origin`,
            data: { titreAR, idUsine },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: DELETE_AR, payload: { id } });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
