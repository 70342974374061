import React, { useState } from "react";
import "../MenuContextuel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useParams } from "react-router-dom";
import printJS from "print-js";
import { saveAs } from "file-saver";
import axios from "axios";
import { getPt } from "../../../../Redux/actions/pt.actions";
import { useDispatch, useSelector } from "react-redux";
import { buildDocUrl } from "../../../../Utils/utils";

function MenuContextuelDoc({ doc, documents, name, isGenerated }) {
    const [modal, setModal] = useState(false);
    const { id } = useParams();

    const dispatch = useDispatch();
    const toggleModalMC = () => {
        setModal(!modal);
    };

    const [input, setInput] = useState(false);

    const [newNam, setNewNam] = useState("");

    const userReducer = useSelector((state) => state.userReducer);

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const handleDownload = async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/docs/download/${doc.filename}`,
            responseType: "arraybuffer",
        })
            .then((res) => {
                let filename = doc.filename;
                if (filename.includes(".pdf")) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    saveAs(blob, filename);
                } else if (filename.includes(".jpg")) {
                    const blob = new Blob([res.data], {
                        type: "image/jpeg",
                    });
                    saveAs(blob, filename);
                } else if (filename.includes(".png")) {
                    const blob = new Blob([res.data], {
                        type: "image/png",
                    });
                    saveAs(blob, filename);
                } else {
                    const blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    saveAs(blob, filename);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleShow = async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/docs/download/${doc.filename}`,
            responseType: "arraybuffer",
        })
            .then((res) => {
                let filename = doc.filename;
                if (filename.includes(".pdf")) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf",
                    });

                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL);
                } else if (filename.includes(".jpg")) {
                    const blob = new Blob([res.data], {
                        type: "image/jpeg",
                    });

                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL);
                } else if (filename.includes(".png")) {
                    const blob = new Blob([res.data], {
                        type: "image/png",
                    });

                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handlePrint = async () => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/docs/download/${doc.filename}`,
            responseType: "arraybuffer",
        })
            .then((res) => {
                let filename = doc.filename;
                if (filename.includes(".pdf")) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf",
                    });

                    const blobURL = URL.createObjectURL(blob);

                    printJS({ printable: blobURL, type: "pdf", showModal: true });
                } else {
                    alert("Vous ne pouvez imprimer que des fichiers PDF");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDelete = async () => {
        let key = "";
        Object.keys(documents).map((docs) => {
            if (documents[docs].filename === doc.filename) {
                key = docs;
            }
        });

        let tmp = documents;
        delete tmp[key];
        if (Object.keys(tmp).length === 0) {
            tmp = null;
        }

        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}/docs/${id}`,
            data: { filename: doc.filename, docs: tmp },
            withCredentials: true,
        })
            .then(async () => {
                alert("Document supprimé");
                window.location = window.location;
                //await dispatch(getPt(userReducer.id_hash_usine));
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans la suppression");
            });
    };

    const handleToggleRename = () => {
        setInput(!input);
    };

    const handleRename = async () => {
        const docOldName = doc.filename;
        const newFilename = id + newNam;

        let key = "";
        Object.keys(documents).map((docs) => {
            if (documents[docs].filename === doc.filename) {
                key = docs;
            }
        });

        console.log(newFilename);

        let tmp = documents;
        tmp[key].filename = newFilename;

        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/docs/${id}`,
            data: {
                oldName: docOldName,
                newName: newFilename,
                docs: tmp,
            },
            withCredentials: true,
        })
            .then(async () => {
                alert("Document renommé");
                setInput(!input);
                window.location = window.location;
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans le renommage");
            });
    };

    const fileExtension = doc.filename.split(".").pop();

    const isPreviewable = fileExtension === "pdf" || fileExtension === "jpg" || fileExtension === "png";

    return (
        <div className="menu_contextuel">
            <button onClick={toggleModalMC} className="bouton_mc">
                <FontAwesomeIcon icon={faBars} />
            </button>

            {modal && !input && (
                <div className="pos_modal">
                    <div className="modal_mc">
                        {isGenerated ? (
                            <ul>
                                {isPreviewable && (
                                    <li>
                                        <a href={buildDocUrl(doc.filename)} rel="noreferrer" target="_blank">
                                            Visualiser
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <a href={buildDocUrl(doc.filename)}>Télécharger</a>
                                </li>
                            </ul>
                        ) : (
                            <>
                                {isPreviewable && <div onClick={handleShow}>Visualiser</div>}
                                <div onClick={handleDownload}>Télécharger</div>
                                <div onClick={handlePrint}>Imprimer</div>
                                <div onClick={handleDelete}>Supprimer</div>
                                <div onClick={handleToggleRename}>Renommer</div>
                            </>
                        )}
                    </div>
                </div>
            )}

            {input && (
                <>
                    <input
                        type="text"
                        defaultValue={name}
                        style={{ marginLeft: "3%", width: "40%" }}
                        maxLength={50}
                        onChange={(e) => setNewNam(e.target.value)}
                    ></input>
                    <button className="renameDoc" onClick={handleRename}>
                        Valider
                    </button>
                    <button className="renameDoc" onClick={() => setInput(!input)}>
                        Annuler
                    </button>
                </>
            )}
            <iframe name="my-frame" id="my-frame" title="my-frame" style={{ display: "none" }}></iframe>
        </div>
    );
}

export default MenuContextuelDoc;
