import { useMemo } from "react";
import { isEmpty } from "../../../Utils/utils";
import { Row } from "./OptionalRow";

export default function OptionalTable({ columns = [], rows = [] }) {
    const filteredRows = useMemo(() => rows.filter((r) => r.values.some((v) => !isEmpty(v))), [rows]);
    const isTableEmpty = useMemo(() => filteredRows.length <= 0, [filteredRows]);

    if (isTableEmpty) {
        return null;
    }

    return (
        <table className="table table-bordered table-sm align-middle">
            <thead className="theadValidation">
                <tr>
                    {columns.map((c) => {
                        if (typeof c === "string") {
                            return (
                                <th key={c} scope="col">
                                    {c}
                                </th>
                            );
                        }
                        return (
                            <th key={c.head} scope="col" colSpan={c.colSpan}>
                                {c.head}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {filteredRows.map((r) => {
                    return <Row key={r.head} head={r.head} values={r.values} />;
                })}
            </tbody>
        </table>
    );
}
