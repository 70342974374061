import React, { useState } from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { NavLink, Link } from "react-router-dom";

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <>
            <div className="navbar_mobile">
                <div className="burger-menu-button" onClick={openMenu}>
                    <FontAwesomeIcon icon={mobileMenuOpen ? faClose : faBars} size="lg" color="white" />
                    <span style={{ color: "white", paddingLeft: "8px" }}>{mobileMenuOpen ? "Fermer" : "Menu"}</span>
                </div>
            </div>
            <div className={`navbar_pt ${mobileMenuOpen ? "mobile_menu_open" : ""}`}>
                <div className="top_navbar">
                    <Link to="/accueil">
                        <button className="nav-link">
                            Accueil
                            <FontAwesomeIcon icon={faHome} />
                        </button>
                    </Link>
                    <NavLink end to="/pt/description/0" className="nav-link">
                        Nouveau PT
                    </NavLink>
                </div>
                <div className="pt_navbar">
                    <div className="text_navbar">Permis de Travaux</div>
                    <NavLink end to="/pt" className="nav-link">
                        Ouverts
                    </NavLink>
                    <NavLink end to="/clotures" className="nav-link">
                        Cloturés
                    </NavLink>
                    <NavLink end to="/favoris" className="nav-link">
                        Favoris
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default Navbar;
