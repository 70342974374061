import React from "react";
import { CardCommentairePDF } from "./CardCommentairePDF";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";

const ROWS_NUMBER = 13;

// Using a class component, everything works without issue
export class FinChantierPDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">VI - FIN DE CHANTIER</h1>

                    <div
                        style={{
                            marginLeft: "50px",
                            marginRight: "50px",
                            marginTop: "10px",
                        }}
                    >
                        <table className="table table-bordered table-sm align-middle">
                            <thead className="theadValidation">
                                <tr>
                                    <th scope="col">TITRE</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Travaux terminés</th>
                                    <td style={{ width: "60px" }}></td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Propreté : Zone de travaux propre et rangée. Tri des déchets respectés.
                                    </th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Permis spécifiques cloturés</th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginLeft: "50px", marginRight: "50px" }}>
                        <table className="table table-bordered table-sm align-middle">
                            <thead className="theadValidation">
                                <tr>
                                    <th scope="col">DATE</th>
                                    <th scope="col">COMMENTAIRES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th style={{ height: "700px" }}></th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="pagebreak"></div>

                    <div
                        style={{
                            marginLeft: "50px",
                            marginRight: "50px",
                            marginTop: "10px",
                        }}
                    >
                        <table className="table table-bordered table-sm align-middle">
                            <thead className="theadValidation">
                                <tr>
                                    <th scope="col">VALIDATEUR</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col">SIGNATURE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(Array(ROWS_NUMBER), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <th style={{ height: "65px" }}></th>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
