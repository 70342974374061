import React, { useEffect, useState } from "react";
import axios from "axios";

const Pointage = ({ contact, date, pointage, handleUpdatePointage, intervenant, disabled }) => {
    const [modal, setModal] = useState(false);

    const [horaireArrivee, setHoraireArriver] = useState("");
    const [horaireDepart, setHoraireDepart] = useState("");
    const [tempsPause, setTempsPause] = useState("");

    useEffect(() => {
        if (pointage !== undefined) {
            setHoraireArriver(pointage.arrivee);
            setHoraireDepart(pointage.depart);
            setTempsPause(pointage.pause);
        } else {
            setHoraireArriver("");
            setHoraireDepart("");
            setTempsPause("");
        }
    }, [pointage, date]);

    const handleSubmit = () => {
        const arrivee = new Date("01/01/1970 " + horaireArrivee + ":00");
        const pause = new Date("01/01/1970 " + tempsPause + ":00");
        const depart = new Date("01/01/1970 " + horaireDepart + ":00");

        const tot = depart - arrivee - pause;
        let diff = {};

        let tmp = Math.floor(tot / 1000);
        tmp = Math.floor(tmp / 60);
        diff.min = tmp % 60;

        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = (tmp % 24) - 1;

        let totFinal = diff.hour.toString().padStart(2, "0") + ":" + diff.min.toString().padStart(2, "0");

        // if (diff.min === 0) {
        //     totFinal = totFinal + "0";
        // }

        if (totFinal.includes("NaN")) {
            totFinal = "";
        }

        const datas = {
            jour: date,
            arrivee: horaireArrivee,
            depart: horaireDepart,
            pause: tempsPause,
            total: totFinal,
        };

        handleUpdatePointage(datas, intervenant);
    };

    return (
        <div>
            <button onClick={() => setModal(!modal)} className="boutonPointageIntervenant" disabled={disabled}>
                {pointage ? (pointage.total !== "" ? pointage.total : "Pointage") : "Pointage"}
            </button>

            {modal && (
                <div className="AddValidateur_overlay" >
                    <div className="AddValidateur_wrapper" >
                        <div className="AddValidateur_modal" >
                            <div className="AddValidateur_header">
                                Pointage d'arrivée
                                <button type="button" className="modal-close-button" onClick={() => setModal(!modal)}>
                                    X
                                </button>
                            </div>
                            <div>
                                <div className="infoValidateur">
                                    <p style={{ paddingBottom: "20px" }}>{contact.prenom}</p>
                                    <p style={{ paddingBottom: "20px" }}>{contact.nom}</p>
                                    <p style={{ paddingBottom: "30px" }}>{date}</p>
                                </div>

                                <div style={{ paddingBottom: "60px" }}>
                                    <h4>HORAIRE D'ARRIVEE</h4>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="time"
                                            min="06:00"
                                            max="19:00"
                                            defaultValue={pointage ? pointage.arrivee : ""}
                                            className="inputDepart"
                                            style={{ marginBottom: "10px" }}
                                            aria-colcount={15}
                                            value={horaireArrivee}
                                            onChange={(e) => setHoraireArriver(e.target.value)}
                                        />
                                        <label style={{ marginLeft: "20px" }}>Choisissez une horaire</label>
                                    </div>
                                </div>

                                <div style={{ paddingBottom: "60px" }}>
                                    <h4>TEMPS DE PAUSE</h4>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="time"
                                            className="inputDepart"
                                            defaultValue={pointage ? pointage.pause : ""}
                                            onChange={(e) => setTempsPause(e.target.value)}
                                            style={{ marginBottom: "10px" }}
                                        />
                                        <label style={{ marginLeft: "20px" }}>Temps</label>
                                    </div>
                                </div>

                                <div style={{ paddingBottom: "40px" }}>
                                    <h4>HORAIRE DE DEPART</h4>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="time"
                                            className="inputDepart"
                                            defaultValue={pointage ? pointage.depart : ""}
                                            onChange={(e) => setHoraireDepart(e.target.value)}
                                            style={{ marginBottom: "10px" }}
                                        />
                                        <label style={{ marginLeft: "20px" }}>Choisissez une horaire</label>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="modal_button_imprimer_validateur"
                                    onClick={handleSubmit}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pointage;
