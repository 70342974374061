import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../Utils/utils";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./PDF/PDFPT/ComponentToPrint";
import { getRisques } from "../../Redux/actions/risques.actions";
import axios from "axios";

const EnvoiMailPt = ({ pt, show, onClose }) => {
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);
    const risqueReducer = useSelector((state) => state.risquesReducer);

    const [done, setDone] = useState(false);
    const [wait, setWait] = useState(true);
    const [wait2, setWait2] = useState(true);
    const [eu, setEu] = useState({});
    const [ee, setEe] = useState({});
    const [st, setSt] = useState({});
    const [newDest, setNewDest] = useState("");
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState({});
    const [file, setFile] = useState("");
    const [fileInputs, setFileInputs] = useState({});
    const [fileInputIndex, setFileInputIndex] = useState(null);
    const [utilisateursSupp, setUtilisateursSupp] = useState([]);
    const [print, setPrint] = useState(false);
    const [after, setAfter] = useState(false);
    const [ptPdf, setPtPdf] = useState("");

    const componentRef = useRef();
    const handlePrinted = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setAfter(!after);
        },
    });

    useEffect(() => {
        if (loading) {
            fetchPt();
        } else {
            if (!done) {
                setDatas();
            } else {
                if (wait) {
                    setKeyOrga(Object.keys(orga_sec));

                    //setKeyRisque(Object.keys(mesurePrev));
                    setWait(false);
                } else {
                    if (wait2) {
                        // partie recommandations ORGA SECOURS
                        let arrayTmp = [];
                        for (let i = 0; i < keyOrga.length / 2; i++) {
                            arrayTmp.push(keyOrga[i]);
                        }
                        setFirstPartKeys(arrayTmp);
                        arrayTmp = [];
                        for (let i = keyOrga.length / 2; i < keyOrga.length; i++) {
                            arrayTmp.push(keyOrga[i]);
                        }
                        setSecondPartKeys(arrayTmp);

                        setWait2(false);
                    }
                }
            }
        }
    }, [loading, done, ptReducer, userReducer, wait, wait2]);

    const [eeCon, setEECon] = useState({});
    const [stCon, setSTCon] = useState({});
    const [thePt, setThePT] = useState({});
    const [orga, setOrga] = useState({});
    const [reco, setReco] = useState({});
    const [orga_sec, setOrga_Sec] = useState({});
    const [keyOrga, setKeyOrga] = useState([]);
    const [firstPartKeys, setFirstPartKeys] = useState([]);
    const [secondPartKeys, setSecondPartKeys] = useState([]);
    const [commentaires, setCommentaires] = useState({});
    const [avisChantier, setAvisChantier] = useState({});
    const [signatairesFin, setSignatairesFin] = useState({});

    const [modOpComInfos, setModOpComInfos] = useState({});
    const [theRisqueTitre, setTheRisqueTitre] = useState({});
    const [risques, setRisques] = useState({});

    const [validateur, setValidateur] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);

    const dispatch = useDispatch();

    const fetchPt = async () => {
        if (userReducer.id !== undefined) {
            await dispatch(getRisques());
            setLoading(false);
        }
    };

    const setDatas = () => {
        setEe(JSON.parse(pt.entreprise_exterieure_PT));
        setEu(JSON.parse(pt.entreprise_utilisatrice_PT));
        setEECon(JSON.parse(pt.entreprise_exterieure_PT_contacts));
        setSt(JSON.parse(pt.entreprise_ST_PT));
        setSTCon(JSON.parse(pt.entreprise_ST_PT_contacts));
        setOrga(JSON.parse(pt.organisation_PT));
        setReco(JSON.parse(pt.recommandation_PT));
        setOrga_Sec(JSON.parse(pt.orga_secours));
        setKeyOrga(Object.keys(orga_sec));
        setValidateur(JSON.parse(pt.validateur_PT));
        setCommentaires(JSON.parse(pt.commentaires));
        setAvisChantier(JSON.parse(pt.avis_chantier));
        setSignatairesFin(JSON.parse(pt.signataires_fin));

        if (pt.risque_mode_ope_com !== null) {
            setModOpComInfos(JSON.parse(pt.risque_mode_ope_com));
        }

        if (pt.documents_PT !== null) {
            setDocs(JSON.parse(pt.documents_PT));
        }

        //non empty analyse risque
        if (!isEmpty(risqueReducer[0])) {
            //regarde si première valeur empty
            let tmpArray = [];
            let tmpObject = {};
            risqueReducer.map((risquePT) => {
                if (risquePT.id_pt === pt.id) {
                    tmpArray.push(risquePT.titre_risque);
                    tmpObject[risquePT.id] = risquePT;
                }
            });
            setTheRisqueTitre(tmpArray);
            setRisques(tmpObject);
        }
        setDone(true);
    };

    const cancel = () => {
        show();
    };

    const handleChecked = (checked, mail) => {
        if (checked === true) {
            let tmp = utilisateursSupp;
            tmp.push(mail);
            setUtilisateursSupp(tmp);
        } else {
            let tmp = utilisateursSupp;
            tmp.map((mails, i) => {
                if (mails === mail) {
                    tmp.splice(i, 1);
                }
            });
            setUtilisateursSupp(tmp);
        }
    };

    const handleAddDestinataire = () => {
        if (newDest !== "") {
            const newAddedUsers = addedUsers;
            const newUtilisateursSupp = utilisateursSupp;
            newAddedUsers.push(newDest);
            newUtilisateursSupp.push(newDest);
            setAddedUsers(newAddedUsers);
            setUtilisateursSupp(newUtilisateursSupp);
            setNewDest("");
        }
    };

    const handleAddFileInput = () => {
        const newFileInputIndex = fileInputIndex !== null ? fileInputIndex + 1 : 0;
        const newFileInputs = {
            ...fileInputs,
            [newFileInputIndex]: {},
        };
        console.log(newFileInputIndex);
        console.log(newFileInputs);
        setFileInputIndex(newFileInputIndex);
        setFileInputs(newFileInputs);
    };

    const handleSend = async () => {
        if (ptPdf !== "") {
            if (utilisateursSupp.length !== 0) {
                let files = [];

                Object.keys(file).map(async (key) => {
                    let form = new FormData();
                    form.append("file", "");
                    await form.set("file", file[key]);
                    files.push(file[key].name);
                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}/sendPT`,
                        data: form,
                        withCredentials: true,
                    });

                    form = null;
                });

                for (const fileInput of Object.values(fileInputs)) {
                    for (const [key, value] of Object.entries(fileInput)) {
                        let form = new FormData();
                        form.append("file", "");
                        await form.set("file", value);
                        files.push(value.name);
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}/sendPT`,
                            data: form,
                            withCredentials: true,
                        });

                        form = null;
                    }
                }

                let form = new FormData();
                form.append("file", ptPdf);
                await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}/sendPT`,
                    data: form,
                    withCredentials: true,
                });

                files.push(ptPdf.name);
                console.log(files);

                const datas = {
                    destinataires: utilisateursSupp,
                    files: files,
                };

                await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}/send-pt-mail`,
                    data: { data: datas },
                    withCredentials: true,
                });

                alert("Mail envoyé à " + utilisateursSupp.join(", "));
                setAfter(false);
                onClose();
            } else {
                alert("Aucun destinataire");
            }
        } else {
            alert("Aucun fichier n'est transmis");
        }
    };

    return (
        <div className="AddValidateur_overlay">
            <div className="AddValidateur_wrapper">
                <div className="AddValidateur_modal">
                    <div className="AddValidateur_header">
                        Envoyer par mail
                        <button type="button" className="modal-close-button" onClick={cancel}>
                            X
                        </button>
                    </div>
                    <div className="AddValidateur_content">
                        {done && (
                            <div>
                                <h3>DESTINATAIRES</h3>
                                {validateur &&
                                    Object.entries(validateur).map(([k, v]) => {
                                        if ((!isEmpty(v.prenom) || !isEmpty(v.nom)) && !isEmpty(v.mail)) {
                                            return (
                                                <Checkbox
                                                    key={k}
                                                    label={`${v.prenom} ${v.nom} - ${v.entreprise}`}
                                                    onChange={handleChecked}
                                                    mail={v.mail}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                {/* {eu.contact_suppleant.prenomSuppleant !== undefined && eu.contact_suppleant.mailSuppleant !== undefined &&
                                    <Checkbox label={eu.contact_suppleant.prenomSuppleant+" "+eu.contact_suppleant.nomSuppleant+" - Suppleant"} onChange={handleChecked} mail={eu.contact_suppleant.mailSuppleant}/>

                                }
                                {eu.contact_exploitant.prenomExploitant !== undefined && eu.contact_exploitant.mailExploitant !== undefined &&
                                    <Checkbox label= {eu.contact_exploitant.prenomExploitant+" "+eu.contact_exploitant.nomExploitant+" - Exploitant 1"} onChange={handleChecked} mail={eu.contact_exploitant.mailExploitant}/>
                                }

                                {eu.contact_exploitant2.prenomExploitant2 !== undefined && eu.contact_exploitant2.mailExploitant2 !== undefined &&
                                    <Checkbox label= {eu.contact_exploitant2.prenomExploitant2+" "+eu.contact_exploitant2.nomExploitant2+" - Exploitant 2"} onChange={handleChecked} mail={eu.contact_exploitant2.mailExploitant2}/>
                                }
                                {ee.contact_CdT !== undefined &&  ee.contact_CdT.mailCdT !== undefined &&
                                    <Checkbox label= {ee.contact_CdT.prenomCdT+" "+ee.contact_CdT.nomCdT+" - Chargé des travaux EE"} onChange={handleChecked} mail={ee.contact_CdT.mailCdT}/>
                                }
                                {st.contact_CdT_ST !== undefined && st.contact_CdT_ST.mailCdT_ST !== undefined &&
                                    <Checkbox label= {st.contact_CdT_ST.prenomCdT_ST+" "+st.contact_CdT_ST.prenomCdT_ST+" - Chargé des travaux ES"} onChange={handleChecked} mail={st.contact_CdT_ST.mailCdT_ST}/>
                                } */}
                                {/* {!isEmpty(utilisateursSupp[0]) &&
                                    utilisateursSupp.map((utilisateur) => {
                                        return <Checkbox label={utilisateur} value={true} />;
                                    })} */}
                                {addedUsers.map((addedUser) => {
                                    return (
                                        <Checkbox
                                            key={addedUser}
                                            label={addedUser}
                                            onChange={handleChecked}
                                            mail={addedUser}
                                            value={true}
                                        />
                                    );
                                })}
                                <div style={{ marginBottom: "4%" }}>
                                    <input
                                        type="text"
                                        placeholder="Destinataire supplémentaire (mail)"
                                        style={{
                                            width: "100%",
                                            marginBottom: "2%",
                                            height: "25px",
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                        }}
                                        value={newDest}
                                        onChange={(e) => setNewDest(e.target.value)}
                                    />
                                    <div
                                        style={{
                                            margin: "auto",
                                            width: "100%",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        className="box_vnspt_button"
                                    >
                                        <button onClick={handleAddDestinataire} style={{ margin: 0 }}>
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div>
                            <h3>DOCUMENTS À JOINDRE</h3>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "200px",
                                    overflow: "scroll",
                                }}
                            >
                                <input
                                    type="file"
                                    multiple={true}
                                    onChange={(e) => setFile(e.target.files)}
                                    style={{ width: "auto", minHeight: "30px" }}
                                />
                                {Object.entries(fileInputs).map(([key, fileInput]) => (
                                    <input
                                        key={key}
                                        type="file"
                                        multiple={true}
                                        onChange={(e) =>
                                            setFileInputs({
                                                ...fileInputs,
                                                [key]: e.target.files,
                                            })
                                        }
                                        style={{ width: "auto", minHeight: "30px" }}
                                    />
                                ))}
                            </div>
                            <div
                                style={{
                                    margin: "auto",
                                    width: "100%",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                className="box_vnspt_button"
                            >
                                <button onClick={handleAddFileInput} style={{ marginTop: 10 }}>
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: "center", marginBottom: "2%" }}>
                        <p>
                            En cliquant sur envoyer, un pdf vous sera proposé, il faut le télécharger puis le
                            transmettre dans le formulaire suivant.
                        </p>
                        <button
                            type="button"
                            className="modal_button_imprimer_validateur"
                            onClick={handlePrinted}
                            style={{ margin: "auto" }}
                        >
                            Envoyer
                        </button>
                    </div>
                </div>
            </div>

            {done && (
                <ComponentToPrint
                    ref={componentRef}
                    id={pt.id}
                    pt={pt}
                    eu={eu}
                    ee={ee}
                    eeCon={eeCon}
                    st={st}
                    stCon={stCon}
                    orga={orga}
                    reco={reco}
                    orga_sec={orga_sec}
                    firstKeys={firstPartKeys}
                    secondKeys={secondPartKeys}
                    modOpComInfos={modOpComInfos}
                    risqueTitre={theRisqueTitre}
                    risques={risques}
                    validateur={validateur}
                    avisChantier={avisChantier}
                    commentaires={commentaires}
                    signatairesFin={signatairesFin}
                />
            )}

            {after && (
                <div
                    style={{
                        backgroundColor: "rgba(28, 25, 23, 0.60)",
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        zIndex: 5000,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div>
                        <input
                            type="file"
                            style={{ width: "auto", height: "auto" }}
                            accept="application/pdf"
                            onChange={(e) => setPtPdf(e.target.files[0])}
                        />
                        <br />
                        <div className="box_vnspt_button">
                            <button style={{ width: "50%", margin: "auto", marginTop: "2%" }} onClick={handleSend}>
                                Valider
                            </button>
                            <button
                                style={{ width: "50%", margin: "auto", marginTop: "2%" }}
                                onClick={() => setAfter(!after)}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const Checkbox = ({ label, value, onChange, mail }) => {
    return (
        <label style={{ marginBottom: "20px", marginLeft: "20px", display: "flex", alignItems: "center" }}>
            <input
                type="checkbox"
                defaultChecked={value}
                onClick={(e) => onChange(e.target.checked, mail)}
                style={{ marginRight: "15px", width: "20px", height: "20px" }}
            />
            {label}
        </label>
    );
};

export default EnvoiMailPt;
