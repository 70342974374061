import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPt, updateOrgaPT } from "../../../../Redux/actions/pt.actions";
import CardParagraphReco from "./CardParagraphReco";
import { text } from "@fortawesome/fontawesome-svg-core";
import userReducer from "../../../../Redux/reducers/user.reducer";
import { useParams } from "react-router-dom";
import NewPTContent from "../NewPTContent";
import { usePrompt } from "../../../../hooks/usePrompt";

const CardRecommandations = ({ pt, preventRefresh }) => {
    const { id } = useParams();

    const [data, setData] = useState({
        parking: "",
        zone_stockage: "",
        zone_restauration: "",
        zone_travail: "",
        toilettes: "",
    });

    const userReducer = useSelector((state) => state.userReducer);

    const [orga, setOrga] = useState({});
    const [reco, setRECO] = useState({});
    const [textes, setTextes] = useState({});
    const [keys, setKeys] = useState([]);
    const [done, setDone] = useState(false);
    const [ee, setEE] = useState({});
    const [loading, setLoading] = useState(true);

    const [isDirty, setIsDirty] = useState(false);

    usePrompt(
        "Vous avez effecté des modifications sur cette page. Voulez-vous vraiment quitter sans sauvegarder ?",
        isDirty
    );

    //FINIR L'AFFICHAGE DE ORGA_SECOURS

    useEffect(() => {
        if (loading) {
            if (pt.id !== undefined) {
                setOrga(JSON.parse(pt.organisation_PT));
                setRECO(JSON.parse(pt.recommandation_PT));
                setTextes(JSON.parse(pt.orga_secours));
                setEE(JSON.parse(pt.entreprise_exterieure_PT));
                setLoading(false);
            }
        } else {
            document.getElementById("avant_inter").innerHTML = reco.avant_intervention;
            document.getElementById("mode_ope").innerHTML = reco.mode_operatoire;
            setKeys(Object.keys(textes));
            setDone(true);

            handleSetData();
        }
    }, [pt, loading, done]);

    const handleSetData = () => {
        const object = {};
        const handleData = (id, value) => {
            object[id] = value;
        };
        if (orga !== null) {
            if (orga.parking !== undefined) {
                handleData("parking", orga.parking);
            }

            if (orga.zone_stockage !== undefined) {
                handleData("zone_stockage", orga.zone_stockage);
            }

            if (orga.zone_restauration !== undefined) {
                handleData("zone_restauration", orga.zone_restauration);
            }

            if (orga.zone_stockage !== undefined) {
                handleData("zone_stockage", orga.zone_stockage);
            }

            if (orga.zone_travail !== undefined) {
                handleData("zone_travail", orga.zone_travail);
            }

            if (orga.toilettes !== undefined) {
                handleData("toilettes", orga.toilettes);
            }
        }

        setData(object);
    };

    const dispatch = useDispatch();

    const submit = async () => {
        if (pt.validation_ec === 0) {
            setIsDirty(false);
            data.zone_travail = document.getElementById("zone_travail").innerText;

            const datas = {
                parking: data.parking,
                zone_stockage: data.zone_stockage,
                zone_travail: data.zone_travail,
                zone_restauration: data.zone_restauration,
                toilettes: data.toilettes,
            };

            await dispatch(updateOrgaPT(pt.id, datas));
            await dispatch(getPt(userReducer.id_hash_usine));
        } else {
            alert("Le PT a commencé à être signé, impossible de le modifier");
        }
    };

    function handle(e) {
        setIsDirty(true);
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
        console.log(newdata);
    }

    return (
        <NewPTContent>
            <form onSubmit={preventRefresh}>
                <div className="orga_recommandations">
                    <div className="name_newpt">ORGANISATION DU CHANTIER</div>
                    <div className="content_organewpt_input">
                        <div className="d-flex align-items-center">
                            <label style={{ width: "150px" }}>Parking :</label>
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="parking"
                                placeholder="Lieu de parking"
                                defaultValue={orga !== null ? orga.parking : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <label style={{ width: "150px" }}>Stockage :</label>
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="zone_stockage"
                                placeholder="Zone de stockage de matériel"
                                defaultValue={orga !== null ? orga.zone_stockage : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <label style={{ width: "150px" }}>Restauration :</label>
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="zone_restauration"
                                placeholder="Zone de restauration"
                                defaultValue={orga !== null ? orga.zone_restauration : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{ height: "50px" }}>
                            <label style={{ width: "135px" }}>Zone de Travail :</label>
                            <p id="zone_travail" >
                                Site : {ee.site_EE_PT ? ee.site_EE_PT : ""} / Atelier :{" "}
                                {ee.zone_travail_EE ? ee.zone_travail_EE : ""} / Ligne :{" "}
                                {ee !== null ? ee.ligne_EE : ""}
                            </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <label style={{ width: "150px" }}>Toilettes :</label>
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="toilettes"
                                placeholder="Toilettes"
                                defaultValue={orga !== null ? orga.toilettes : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                    </div>
                </div>
                {id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? null : (
                    <button
                        onClick={submit}
                        className="button_add_do"
                        type="submit"
                        style={{
                            height: "50px",
                            backgroundColor: "#4aabe7",
                            color: "#fff",
                            border: "2px solid #4aabe7",
                            marginBottom: "30px",
                            marginTop: "50px",
                            borderRadius: "5px",
                            padding: "0 20px",
                        }}
                    >
                        Sauvegarder les modifications
                    </button>
                )}

                <div className="intervention_recommandations">
                    {!loading && (
                        <>
                            <div className="name_newpt">PRÉALABLE A L'INTERVENTION DE L'ENTREPRISE</div>

                            <div className="titre_bleu_recommandation">Avant l'intervention</div>

                            <p style={{ whiteSpace: "pre-line" }} id="avant_inter"></p>

                            <div className="titre_bleu_recommandation">Mode opératoire</div>

                            <p id="mode_ope"></p>

                            <div className="titre_bleu_recommandation">Organisation des secours</div>
                        </>
                    )}

                    {!loading &&
                        done &&
                        keys.map((k) => {
                            return <CardParagraphReco text={textes[k]} />;
                        })}
                </div>
            </form>
        </NewPTContent>
    );
};

export default CardRecommandations;
