import axios from 'axios';

export const GET_PP = "GET_PP";

export const getPp = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/pp`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type : GET_PP, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}