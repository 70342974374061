import React, { useEffect, useState } from "react";
import "./AnalyseRisque.css";
import BoutonAR from "./BoutonARAdmin/BoutonAR";
import NavbarAdmin from "../NavbarAdmin/NavbarAdmin";
import HeaderAdmin from "../HeaderAdmin/HeaderAdmin";
import AddAR from "../HeaderAdmin/AddUser/AddAR";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../Utils/utils";
import { getPt } from "../../../Redux/actions/pt.actions";
import { getRisques } from "../../../Redux/actions/risques.actions";
import {
    getRisqueOriginaux,
    setRisqueOriginauxDesc,
    setRisqueOriginauxMP,
    setRisqueOriginauxTitre,
} from "../../../Redux/actions/risqueOriginaux.actions";
import risqueOriginauxReducer from "../../../Redux/reducers/risqueOriginaux.reducer";

const AnalyseRisqueAdmin = () => {
    const [loading, setLoading] = useState(true);

    const userReducer = useSelector((state) => state.userReducer);
    const [idUsine, setIdUsine] = useState("");

    const risqueOriginauxReducer = useSelector((state) => state.risqueOriginauxReducer);

    const [risqueKeys, setRisqueKeys] = useState([]);

    const dispatch = useDispatch();

    let object = {};

    const fetchRisques = async () => {
        setIdUsine(userReducer.id_hash_usine);
        await dispatch(getRisqueOriginaux());
    };

    const setRisquesKeys = () => {
        let risques = [];
        if (!isEmpty(risqueOriginauxReducer[0])) {
            risqueOriginauxReducer.map((risque) => {
                if (risque.id_hash_usine === idUsine) {
                    risques.push(risque);
                }
            });
        }
        setRisqueKeys(risques);
    };

    useEffect(() => {
        if (userReducer.id !== undefined) {
            if (userReducer.admin !== 1) {
                window.location = "/accueil";
            } else {
                if (loading) {
                    if (userReducer.id !== undefined) {
                        fetchRisques();
                        setLoading(false);
                    }
                } else {
                    setRisquesKeys();
                }
            }
        }
    }, [loading, userReducer, risqueOriginauxReducer]);

    const handleSetDatas = async (data, titreAR) => {
        risqueOriginauxReducer.map(async (risque) => {
            if (risque.id_hash_usine.toString() === idUsine.toString() && risque.titre_risque === titreAR) {
                await dispatch(setRisqueOriginauxMP(risque.id, data));
            }
        });
    };

    const handleSetDescription = async (titreAR, description) => {
        risqueOriginauxReducer.map(async (risque) => {
            if (risque.id_hash_usine.toString() === idUsine.toString() && risque.titre_risque === titreAR) {
                await dispatch(setRisqueOriginauxDesc(risque.id, description));
            }
        });
    };

    const handleSetTitre = async (titreAR, titre) => {
        risqueOriginauxReducer.map(async (risque) => {
            if (risque.id_hash_usine.toString() === idUsine.toString() && risque.titre_risque === titreAR) {
                await dispatch(setRisqueOriginauxTitre(risque.id, titre));
            }
        });
    };

    const handleAddNewCond = async (titreAR, data) => {
        risqueOriginauxReducer.map(async (risque) => {
            if (risque.id_hash_usine.toString() === idUsine.toString() && risque.titre_risque === titreAR) {
                await dispatch(setRisqueOriginauxMP(risque.id, data));
            }
        });
    };

    return (
        <div>
            <NavbarAdmin />
            <HeaderAdmin titre="Analyse de risque" />
            <AddAR idUsine={idUsine} className="desktop-only" />

            <div
                className="content_ARnewpt aside-menu-content under-header-content content-padding"
                style={{ marginTop: "100px" }}
            >
                <AddAR idUsine={idUsine} className="mobile-only" />

                {!loading &&
                    !isEmpty(risqueKeys[0]) &&
                    risqueKeys.map((risque) => {
                        return (
                            <BoutonAR
                                id={risque.id}
                                titreAR={risque.titre_risque}
                                descriptionAR={risque.description_risque}
                                mesure_prevention={
                                    risque.mesures_prevention !== null &&
                                    risque.mesures_prevention !== undefined &&
                                    risque.mesures_prevention !== ""
                                        ? JSON.parse(risque.mesures_prevention)
                                        : null
                                }
                                handleSetData={handleSetDatas}
                                handleEditDescription={handleSetDescription}
                                handleEditTitre={handleSetTitre}
                                handleAddNewCond={handleAddNewCond}
                                idUsine={idUsine}
                            />
                        );
                    })}

                {/* <button
                    className="button_add_do"
                    style={{
                        maxWidth: "200px",
                        width: "100%",
                        height: "50px",
                        backgroundColor: "#4aabe7",
                        color: "#fff",
                        border: "2px solid #4aabe7",
                        marginBottom: "30px",
                        borderRadius: "5px",
                    }}
                >
                    Sauvegarder les modifications
                </button> */}
            </div>
        </div>
    );
};

export default AnalyseRisqueAdmin;
