import React, { useEffect, useState } from "react";
import "./General.css";
import NavbarReglage from "../NavbarReglage/NavbarReglage";
import HeaderReglage from "../HeaderReglage/HeaderReglage";
import { useSelector } from "react-redux";
import ChangePassword from "../../Login/ChangePassword";
import ChangeProfilPicture from "../../Login/ChangeProfilPicture";
import { buildProfilePictureUrl, isEmpty } from "../../../Utils/utils";
import random_user from "../../../assets/images/logo/random_user.png";
import axios from "axios";
import cookie from "js-cookie";

const General = (props) => {
    const [showChangePass, setShowChangePass] = useState(false);
    const [showChangePhoto, setShowChangePhoto] = useState(false);
    const [photo, setPhoto] = useState("");
    const [loading, setLoading] = useState(true);

    const userReducer = useSelector((state) => state.userReducer);

    useEffect(() => {
        if (loading) {
            if (userReducer.id !== undefined) {
                if (userReducer.photo !== null && userReducer.photo !== undefined && userReducer.photo !== "") {
                    setPhoto(userReducer.photo);
                }

                setLoading(false);
            }
        }
    }, [userReducer, loading]);

    const removeCookie = (key) => {
        cookie.remove(key, { expires: 1 });
    };

    const handleDelete = async () => {
        if (
            window.confirm(
                "Voulez-vous vraiment supprimer votre compte ? Une fois validée, cette action sera irréversible et vous serez immédiatement déconnecté."
            )
        ) {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}/deleteAccount`,
                data: { id: userReducer.id },
                withCredentials: true,
            }).then(async (res) => {
                if (res.data === true) {
                    await axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}/logout`,
                        withCredentials: true,
                    })
                        .then(() => removeCookie("jwt"))
                        .catch((err) => console.log(err));
                    window.location = "/";
                } else {
                    alert("Erreur du serveur, impossible de supprimer le compte");
                }
            });
        }
    };

    return (
        <div>
            <NavbarReglage />
            <HeaderReglage titre="Général" />

            <div className="content_Reglage">
                <div className="reglage-section">
                    <h3>PHOTO DE PROFIL</h3>
                    {!loading && (
                        <div
                            style={{
                                height: "200px",
                                width: "200px",
                                backgroundColor: "grey",
                                borderRadius: "100px",
                                marginBottom: "30px",
                                overflow: "hidden",
                            }}
                        >
                            <img
                                src={photo !== "" ? buildProfilePictureUrl(photo) : random_user}
                                alt="profile pic"
                                style={{ width: "200px", objectFit: "cover", height: "200px" }}
                            />
                        </div>
                    )}

                    <button className="boutonGeneral1" onClick={() => setShowChangePhoto(!showChangePhoto)}>
                        Changer la photo
                    </button>
                    {showChangePhoto && <ChangeProfilPicture />}
                </div>
                <button className="boutonGeneral1" onClick={() => setShowChangePass(!showChangePass)}>
                    Changer mon Mot de passe
                </button>
                <div className="reglage-section">{showChangePass && <ChangePassword />}</div>

                <h3 style={{ marginTop: "40px" }}>RGPD</h3>
                <p style={{ color: "grey", marginBottom: "20px", maxWidth: "500px" }}>
                    Vous pouvez effacer définitivement l’ensemble du compte ainsi que toutes ses données. Attention, il
                    n’est pas possible de restaurer le compte après sa suppression.
                </p>
                <button className="boutonGeneral2" onClick={handleDelete}>
                    Supprimer le compte
                </button>
            </div>
        </div>
    );
};

export default General;
