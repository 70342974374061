import React from "react";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";
import { BoutonARPDFV } from "./BoutonARPDF";

// Using a class component, everything works without issue
export class AnalyseRisquePDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">III - ANALYSE DE RISQUE</h1>

                    <div className="DescriptionPDFContent" style={{ marginTop: "10px" }}>
                        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                />
                                <label htmlFor="checkbox">Mode opératoire fourni par l'EE</label>
                                <br />
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                />
                                <label htmlFor="checkbox">Acte de sous-traitance signé et renvoyé à l'EE</label>
                                <br />
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                />
                                <label htmlFor="checkbox">
                                    Veille réglementaire effectuée sur la plateforme ELYFEC
                                </label>
                            </div>
                            <table className="table table-bordered table-sm align-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">DOMAINE / Risque</th>
                                        <th scope="col">Mesure de prévention</th>
                                        <th scope="col">EU</th>
                                        <th scope="col">EE/ES</th>
                                    </tr>
                                </thead>
                                <tbody style={{ margin: 50 }}>
                                    {this.props.risques !== null &&
                                        this.props.risques !== undefined &&
                                        Object.values(this.props.risques).map((risque) => {
                                            return <BoutonARPDFV key={risque.titre_risque} risque={risque} />;
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <p style={{ width: "80%", margin: "0 auto", fontSize: "1.2rem", textAlign: "center" }}></p>
                <div className="pagebreak"></div>
            </div>
        );
    }
}
