import {ADD_FAVORIS, GET_FAVORIS} from "../actions/favoris.actions";

const initialState = {};

export default function favorisReducer( state = initialState, action) {

    switch (action.type) {
        case GET_FAVORIS:
            return action.payload;

        default:
            return state;
    }
}