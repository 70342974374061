import {
    DELETE_AR,
    GET_RISQUEORIGIN, NEW_AR,
    SET_RISQUEORIGINDESC,
    SET_RISQUEORIGINMP,
    SET_RISQUEORIGINTITRE
} from "../actions/risqueOriginaux.actions";

const initialState = {};

export default function risqueOriginauxReducer( state = initialState, action) {

    switch (action.type) {
        case GET_RISQUEORIGIN:
            return action.payload;

        case SET_RISQUEORIGINMP:
            return state.map((risque) => {
                if(risque.id === action.payload.id) {
                    return {
                        ...risque,
                        mesures_prevention : JSON.stringify(action.payload.data)
                    }
                } else {
                    return risque;
                }
            })

        case SET_RISQUEORIGINDESC:
            return state.map((risque) => {
                if(risque.id === action.payload.id) {
                    return {
                        ...risque,
                        description_risque : action.payload.desc
                    }
                } else {
                    return risque;
                }
            })

        case SET_RISQUEORIGINTITRE:
            return state.map((risque) => {
                if(risque.id === action.payload.id) {
                    return {
                        ...risque,
                        titre_risque : action.payload.titre
                    }
                } else {
                    return risque;
                }
            })

        case NEW_AR:
            return state;

        case DELETE_AR:
            return state.filter((risque) =>
                risque.id !== action.payload.id
            )

        default:
            return state;
    }
}
