import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CardDesc from "./CardDesc";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../../../Utils/utils";
import { getPt } from "../../../../../Redux/actions/pt.actions";
import { getUser } from "../../../../../Redux/actions/user.actions";

const CreateDesc = ({ isDirty, setIsDirty }) => {
    const { id } = useParams();

    // all datas from PT are in ptReducer
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const getPT = async () => {
        if (isEmpty(ptReducer[0])) {
            await dispatch(getPt(userReducer.id_hash_usine));
        }
    };

    useEffect(() => {
        if (loading && userReducer.id_hash_usine !== undefined) {
            getPT();
            setLoading(false);
        }
    }, [loading, userReducer]);

    const preventRefresh = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            {loading && <div>Loading ...</div>}
            {!loading &&
                !isEmpty(ptReducer[0]) &&
                ptReducer[0].map((pt) => {
                    if (pt.id.toString() === id) {
                        return (
                            <CardDesc
                                setIsDirty={setIsDirty}
                                pt={pt}
                                isDirty={isDirty}
                                preventRefresh={preventRefresh}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            {!loading && id === "0" && (
                <CardDesc setIsDirty={setIsDirty} pt={null} isDirty={isDirty} preventRefresh={preventRefresh} />
            )}
        </div>
    );
};

export default CreateDesc;
