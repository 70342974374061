import { useEffect, useState } from "react";
import "./KPI.css";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { getPp } from "../../../Redux/actions/pp.actions";
import { isEmpty } from "../../../Utils/utils";
import { getPt } from "../../../Redux/actions/pt.actions";

const Kpi = () => {
    const userReducer = useSelector((state) => state.userReducer);
    const ppReducer = useSelector((state) => state.ppReducer);
    const ptReducer = useSelector((state) => [state.ptReducer]);

    const dispatch = useDispatch();

    const [showPt, setShowPt] = useState(false);
    const [showPp, setShowPp] = useState(false);
    const [showCoac, setShowCoac] = useState(false);
    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [countPP, setCountPP] = useState(0);
    const [countPT, setCountPT] = useState(0);
    const [countCoac, setCountCoac] = useState(0);

    const fetchData = async () => {
        await dispatch(getPp());
        await dispatch(getPt(userReducer.id_hash_usine));
    };

    const setCountsPP = () => {
        if (!isEmpty(ppReducer[0])) {
            let count = 0;
            ppReducer.map((pp) => {
                if (pp.id_hash_user === userReducer.id_hash && pp.status === "new") {
                    count++;
                }
            });
            setCountPP(count);
        }
    };

    const setCountsPt = () => {
        if (!isEmpty(ptReducer[0])) {
            let count = 0;
            ptReducer[0].map((pt) => {
                if (
                    JSON.parse(pt.entreprise_utilisatrice_PT).contact_RdP.prenomRdP === userReducer.prenom &&
                    JSON.parse(pt.entreprise_utilisatrice_PT).contact_RdP.nomRdP === userReducer.nom &&
                    pt.status === "ouvert"
                ) {
                    count++;
                }
            });
            setCountPT(count);
        }
    };

    const setCountsCoactivite = () => {
        let count = 0;
        if (!isEmpty(ptReducer[0])) {
            count = ptReducer[0].length;
        }

        if (!isEmpty(ppReducer[0])) {
            ppReducer.map((pp) => {
                if (pp.id_hash_usine === userReducer.id_hash_usine) {
                    count++;
                }
            });
        }

        setCountCoac(count);
    };

    useEffect(() => {
        if (loading) {
            if (userReducer.id !== undefined) {
                fetchData();
                if (userReducer.kpi !== null && userReducer.kpi !== undefined) {
                    const kpis = JSON.parse(userReducer.kpi);
                    setShowPt(kpis.pt);
                    setShowPp(kpis.pp);
                    setShowCoac(kpis.coactivite);
                }

                setLoading(false);
            }
        } else {
            if (!done) {
                setCountsPP();
                setCountsPt();
                setCountsCoactivite();
            }
        }
    }, [userReducer, ppReducer, loading, done, ptReducer]);

    return (
        !loading && (
            <div className="KPI">
                {showPt && (
                    <div className="actions te">
                        <div className="cadre">
                            <div className="nombre">{countPT}</div>
                        </div>
                        <p>PT</p>
                    </div>
                )}
                {showPp && (
                    <div className="coactivite te">
                        <div className="cadre">
                            <div className="nombre">{countPP}</div>
                        </div>
                        <p>PP</p>
                    </div>
                )}

                {showCoac && (
                    <div className="deviance te">
                        <div className="cadre">
                            <div className="nombre">{countCoac}</div>
                        </div>
                        <p>Coactivité</p>
                    </div>
                )}
            </div>
        )
    );
};

export default Kpi;
