import axios from 'axios';

export const GET_USER = "GET_USER";

export const getUser = (uid) => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/user/${uid}`,
            withCredentials: true
        })
            .then((res) => {
                const data = JSON.stringify(res.data[0]);
                const final = JSON.parse(data);

                dispatch({type : GET_USER, payload : final})
            })
            .catch((err) => {console.log(err)});
    }
}