import React, { useState } from "react";
import "./AddUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { getRisqueOriginaux, newAr } from "../../../../Redux/actions/risqueOriginaux.actions";
import { useDispatch } from "react-redux";

function AddAR({ idUsine, className }) {
    const [modal, setModal] = useState(false);
    const [titre, setTitre] = useState("");
    const [description, setDescription] = useState("");

    const dispatch = useDispatch();

    const toggleModalAddValidateur = () => {
        setModal(!modal);
    };

    const handleAddNewAR = async () => {
        const data = {
            titre,
            description,
        };

        await dispatch(newAr(idUsine, data));
        await dispatch(getRisqueOriginaux());
        setModal(!modal);
    };

    return (
        <div className={className}>
            <button onClick={toggleModalAddValidateur} className="button_add_validateur button_add_validateur_user">
                <FontAwesomeIcon icon={faCirclePlus} />
                Ajouter une Analyse de Risque
            </button>

            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_headerR">
                                Nouvel Analyse de Risque
                                <button type="button" className="modal-close-button" onClick={toggleModalAddValidateur}>
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <label>Titre de l'AR</label>
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    placeholder="Titre"
                                    onChange={(e) => setTitre(e.target.value)}
                                />

                                <label>Description de l'AR</label>
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <button type="button" className="modal_button_imprimer_validateur" onClick={handleAddNewAR}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddAR;
