import React, { useState } from "react";
import "./ImprimerValidateur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import PDFValidateurs from "../../../../../PDF/PDFValidateurs/PDFValidateurs";

function ImprimerValidateur({ pt, validateurs }) {
    const [modal, setModal] = useState(false);

    const toggleModalImprimerValidateur = () => {
        setModal(!modal);
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    return (
        <div>
            <button onClick={toggleModalImprimerValidateur} className="button_imprimer_validateur">
                <FontAwesomeIcon icon={faPrint} />
                Imprimer
            </button>

            {modal && (
                <div className="ImprimerValidateur_overlay">
                    <div className="ImprimerValidateur_wrapper">
                        <div className="ImprimerValidateur_modal">
                            <div className="ImprimerValidateur_header">
                                Imprimer
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalImprimerValidateur}
                                >
                                    X
                                </button>
                            </div>

                            <PDFValidateurs pt={pt} validateursP={validateurs} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImprimerValidateur;
