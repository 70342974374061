import {GET_USERS, NEW_USER, UPDATE_USER, USER_INACTIVE} from "../actions/users.actions";
import {act} from "@testing-library/react";

const initialState = {};

export default function contactsReducer( state = initialState, action) {

    switch (action.type) {
        case GET_USERS:
            return action.payload;

        case USER_INACTIVE:
            return state.map((user) => {
                if(user.id === action.payload.id) {
                    return{
                        ...user,
                        active : 0
                    }
                } else {
                    return user;
                }
            })

        case NEW_USER:
            return state;

        case UPDATE_USER:
            return state.map((user) => {
                if(user.id === action.payload.id) {
                    return{
                        ...user,
                        id_usine : action.payload.data.usine,
                        mail : action.payload.data.mail,
                        tel : action.payload.data.tel,
                        prenom : action.payload.data.prenom,
                        nom : action.payload.data.nom,
                        active : action.payload.data.active,
                        admin : action.payload.data.admin,
                        habilitation : JSON.stringify(action.payload.data.habilitation),
                        acces : JSON.stringify(action.payload.data.acces),
                    }
                } else {
                    return user;
                }
            })


        default:
            return state;
    }
}