import axios from 'axios';

export const GET_CONTACTS = "GET_CONTACTS";

export const getContacts = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/contacts`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type : GET_CONTACTS, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}