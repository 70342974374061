import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPt } from "../../../../../Redux/actions/pt.actions";
import axios from "axios";
import { useParams } from "react-router-dom";
import { usePrompt } from "../../../../../hooks/usePrompt";

const Checklist = ({ pt }) => {
    const { id } = useParams();

    const [travauxFinis, setTravauxFinis] = useState(false);
    const [zonePropre, setZonePropre] = useState(false);
    const [permis, setPermis] = useState(false);

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);

    const [data, setData] = useState({});
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);

    const [isDirty, setIsDirty] = useState(false);

    usePrompt(
        "Vous avez effecté des modifications sur cette page. Voulez-vous vraiment quitter sans sauvegarder ?",
        isDirty
    );

    const dispatch = useDispatch();

    const setDatas = async () => {
        if (pt.avis_chantier !== null) {
            setData(JSON.parse(pt.avis_chantier));
            setTravauxFinis(data.travauxTermines);
            setZonePropre(data.proprete);
            setPermis(data.permis);
        }
        setLoading(false);
    };

    useEffect(() => {
        setDatas();
    }, [loading, pt]);

    const handleSubmit = async () => {
        const datas = {
            travauxTermines: travauxFinis,
            proprete: zonePropre,
            permis: permis,
        };

        await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/updateAvisChantier/${pt.id}`,
            data: datas,
            withCredentials: true,
        })
            .then(async () => {
                setIsDirty(false);
                alert("Modifications sauvegardées");
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans la sauvegarde");
            });
    };

    return (
        <div>
            <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                CHECKLIST
            </div>
            {!loading && data !== null && (
                <>
                    <div className="form-check" style={{ marginBottom: "15px", color: "grey" }}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="travauxTermines"
                            defaultChecked={data.travauxTermines ? (data.travauxTermines ? true : false) : false}
                            onChange={(e) => {
                                setIsDirty(true);
                                setTravauxFinis(e.target.checked);
                            }}
                            disabled={id !== "0" && pt.status === "clotures" ? true : false}
                        />
                        <label className="form-check-label" htmlFor="travauxTermines">
                            Travaux terminés
                        </label>
                    </div>
                    <div className="form-check" style={{ marginBottom: "15px", color: "grey" }}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="proprety"
                            defaultChecked={data.proprete ? (data.proprete ? true : false) : false}
                            onChange={(e) => {
                                setIsDirty(true);
                                setZonePropre(e.target.checked);
                            }}
                            disabled={id !== "0" && pt.status === "clotures" ? true : false}
                        />
                        <label className="form-check-label" htmlFor="proprety">
                            Propreté : Zone de travaux propre et rangée. Tri des déchets respectés.
                        </label>
                    </div>
                    <div className="form-check" style={{ marginBottom: "15px", color: "grey" }}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="clotures"
                            defaultChecked={data.permis ? (data.permis ? true : false) : false}
                            onChange={(e) => {
                                setIsDirty(true);
                                setPermis(e.target.checked);
                            }}
                            disabled={id !== "0" && pt.status === "clotures" ? true : false}
                        />
                        <label className="form-check-label" htmlFor="clotures">
                            Permis spécifiques cloturés
                        </label>
                    </div>
                </>
            )}

            {id !== "0" && pt.status === "clotures" ? null : (
                <button className="buttonValidationI btn btn-success" style={{ marginLeft: 0 }} onClick={handleSubmit}>
                    Sauvegarder
                </button>
            )}
        </div>
    );
};

export default Checklist;
