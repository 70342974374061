import React, { useRef, useState, useContext, useEffect } from "react";
import AuthContext from "../Context/AuthProvider";
import LeftLogin from "./DesignLogin/LeftLogin";
import logoTop from "../../assets/images/logo/logowhite2.png";
import logoBot from "../../assets/images/logo/logowhite1.png";
import axios from "axios";

function ForgetPassword() {

    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [mail, setMail] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const LOGIN_URL = `${process.env.REACT_APP_API_URL}/forgot-pass`;

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [mail])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios({
                method : 'put',
                url : LOGIN_URL,
                data : {email : mail},
            })
                .then(() => {
                    alert('Aller voir vos mails pour recevoir votre nouveau mot de passe');
                })
                .catch((err) => {
                    console.log(err);
                    alert('Erreur du serveur');
                })
        } catch(e) {
            console.log(e);

        }

    }

    return (
        <>
            <div className="d-flex">
                <LeftLogin />
                
                {success ? (
                    <section>
                        <h1>You are logged in!</h1>
                    </section>
                ) : (
                    <section style={{
                        backgroundColor: '#072872',
                        width: '50%',
                        color: 'white',
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div 
                            className="d-flex flex-column"
                            style={{
                                position: 'fixed',
                                top: '100px',
                                left: '55%'
                            }}> 
                            <img src={logoTop} alt="logo"  />
                            <img src={logoBot} alt="logo"  />
                        </div>
                        <h1>Mot de passe oublié</h1>
                        <form onSubmit={handleSubmit} afterSubmit={() => console.log('yes')} className="d-flex flex-column" style={{width: '35%', marginTop:'20px'}}>
                            <input
                                placeholder="Mail professionnel"
                                type="text"
                                id="mail"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setMail(e.target.value)}
                                value={mail}
                                required
                                style={{height: '50px', marginBottom: '8px', borderRadius: '5px', width: '100%'}}
                            />
                            <button
                                style={{
                                    height: '50px',
                                    backgroundColor: '#1d5399',
                                    color: "white",
                                    border: 'none',
                                    borderRadius: '5px'
                                }}
                            >Envoyer un mail</button>
                        </form>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    </section>
                )}
            </div>
            
        </>
    );
}

export default ForgetPassword;