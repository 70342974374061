import React, {useEffect, useState} from 'react';

const CardParagraphReco = ({text}) => {

    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(loading) {
            setId(Math.random());
            setLoading(false);
        } else {
            document.getElementById(id).innerHTML = text.text;
        }

    }, [loading])


    return (
        <div>
            <div className="st_recommandation">{text.titre}</div>
            {!loading &&
                <p id={id}></p>
            }

        </div>
    );
};

export default CardParagraphReco;