import React from "react";

// Using a class component, everything works without issue
export class CardValidPDF extends React.PureComponent {
    componentDidMount() {
        console.log(this.props.validateur)
    }

    render() {
        return (
            <tr>
                <th scope="row">{this.props.k === "validateur1" ? 'Rédacteur du Permis' :
                    this.props.k === "validateur2" ? 'Suppleant' :
                        this.props.k === "validateur3" ? 'Suppleant2' :
                            this.props.k === "validateur4" ? 'Exploitant' :
                                this.props.k === "validateur5" ? 'Exploitan2' :
                                    this.props.k === "validateur6" ? 'Chargé des travaux' :
                                        this.props.k === "validateur7" ? 'Chargé des travaux ST' : 'Validateur externe'}
                    <br/>
                    {this.props.validateur.prenom} {this.props.validateur.nom}</th>
                <td>{this.props.validateur.date}</td>
                <td>{this.props.validateur.signe === 1 ? this.props.validateur.numerique !== 0 ? 'Numérique' : 'Papier' : 'Non signé'}</td>
            </tr>
        );
    }
}
