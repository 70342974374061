import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";
import { CardValidPDF } from "./CardValidPDF";

// Using a class component, everything works without issue
export class ValidationPDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">IV - VALIDATION</h1>

                    {this.props.validateur ? (
                        <>
                            <p style={{ fontStyle: "italic", textAlign: "center", marginTop: "10px" }}>
                                Liste des validateurs ayant signé numériquement ou manuellement :
                            </p>
                            <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
                                <table className="table table-bordered table-sm align-middle">
                                    <thead className="theadValidation">
                                        <tr>
                                            <th scope="col">VALIDATEUR</th>
                                            <th scope="col">DATE</th>
                                            <th scope="col">TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.props.validateur).map((valid) => {
                                            if (this.props.validateur[valid].prenom !== "") {
                                                return (
                                                    <CardValidPDF validateur={this.props.validateur[valid]} k={valid} />
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <p style={{ fontStyle: "italic", textAlign: "center", marginTop: "10px" }}>
                            Il n'y a pas encore de validateur ayant signé ce permis de travail.
                        </p>
                    )}
                </div>
                <div className="sectionSpacing"></div>
            </div>
        );
    }
}
