import React, { useState, useContext, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { isEmpty } from "../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addFavoris, getFavoris, removeFavoris } from "../../../Redux/actions/favoris.actions";
import Loader from "../../Ui/Loader/Loader";

function FavorisBouton({ pt }) {
    const [isFavoris, setIsFavoris] = useState(false);

    const userReducer = useSelector((state) => state.userReducer);
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const favorisReducer = useSelector((state) => state.favorisReducer);

    const dispatch = useDispatch();

    const [favUser, setFavUser] = useState([]);
    const [loading, setLoading] = useState(false);

    const setFavs = useCallback(async () => {
        let favs = "";
        if (favorisReducer.length !== 0) {
            favorisReducer.map((user) => {
                if (user.id_user === userReducer.id) {
                    favs = user.favoris_pt;
                    favs = favs.slice(1);
                    favs = favs.slice(0, -1);
                    favs = favs.replace(/^\D+/g, "");
                    let array = [];
                    array = favs.split(",");

                    setFavUser(array);

                    if (array.includes(pt.id.toString())) {
                        setIsFavoris(true);
                    } else {
                        setIsFavoris(false);
                    }
                }
            });
        } else {
            setFavUser([]);
            setIsFavoris(false);
        }
    }, [favorisReducer, userReducer, pt.id]);

    useEffect(() => {
        setFavs();
    }, [setFavs]);

    const toggleFav = () => {
        submit();
    };

    const submit = async () => {
        setLoading(true);
        if (!isFavoris) {
            //add-favoris
            dispatch(addFavoris(pt.id, userReducer.id)).then(() => {
                setIsFavoris(true);
                setLoading(false);
            });
        } else {
            //remove-favoris
            dispatch(removeFavoris(pt.id, userReducer.id)).then(() => {
                setIsFavoris(false);
                setLoading(false);
            });
        }
    };

    const preventRefresh = (e) => {
        e.preventDefault();
    };

    return (
        <>
            {loading ? (
                <Loader size={24}></Loader>
            ) : (
                <form onSubmit={preventRefresh}>
                    <button onClick={toggleFav} style={{ border: "none", backgroundColor: "transparent" }}>
                        <FontAwesomeIcon icon={faStar} style={{ color: !isFavoris ? "grey" : "#072872" }} />
                    </button>
                </form>
            )}
        </>
    );
}

export default FavorisBouton;
