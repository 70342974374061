import {combineReducers} from "redux";
import userReducer from './user.reducer';
import ptReducer from "./pt.reducer";
import favorisReducer from "./favoris.reducer";
import usineReducer from './usine.reducer';
import risquesReducer from "./risques.reducer";
import intervenantReducer from './intervenant.reducer';
import contactsReducer from './contacts.reducer'
import entrepriseReducer from './entreprise.reducer';
import ppReducer from './pp.reducer';
import usersReducer from './users.reducer';
import recommandationReducer from './recommandation.reducer';
import risqueOriginauxReducer from "./risqueOriginaux.reducer";

export default combineReducers({
    userReducer, ptReducer, favorisReducer, usineReducer, risquesReducer, intervenantReducer, contactsReducer, entrepriseReducer, ppReducer,
    usersReducer, recommandationReducer, risqueOriginauxReducer
})