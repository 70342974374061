import React, { useEffect, useRef, useState } from "react";
import "./PDFValidateurs.css";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";
import { useDispatch, useSelector } from "react-redux";
import { getPt, updateValidateurs } from "../../../../Redux/actions/pt.actions";
import CardCheckBox from "../../NewPT/AnalyseRisque/BoutonAR/CardCheckBox";
import { filterInvalidValidatorsCallback, isEmpty, printPageStyle } from "../../../../Utils/utils";

const Example = ({ pt, validateursP }) => {
    // partie où l'on récupère les données à imprimer
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => componentRef.current,
        onAfterPrint: async () => {
            if (window.confirm("Avez-vous bien téléchargé le document ?")) {
                const data = validateursP;
                let updated = false;
                Object.entries(validateurs).map(([k, validateur]) => {
                    if (validateur.signe === 2) {
                        data[k] = validateur;
                        data[k].signe = 1;
                        data[k].date = new Date().toJSON().split("T")[0];
                        updated = true;
                    }
                });
                if (updated) {
                    await dispatch(updateValidateurs(pt.id, data));
                }
                window.location = window.location;
            } else {
                setClicked(false);
            }
        },
    });

    const [loading, setLoading] = useState(true);
    const [eu, setEu] = useState({});

    const dispatch = useDispatch();

    const [validateurs, setValidateurs] = useState({});

    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        console.log("SET VALIDATEURS 1", validateursP);
        setValidateurs(validateursP);
    }, [validateursP]);

    useEffect(() => {
        if (pt !== undefined) {
            const tmp = JSON.parse(pt.validateur_PT);
            let array = Object.keys(tmp);
            const tmpObj = {};
            setEu(JSON.parse(pt.entreprise_utilisatrice_PT));
            console.log(JSON.parse(pt.entreprise_utilisatrice_PT));
            for (const key in array) {
                if (
                    (tmp[array[key]].prenom !== undefined || tmp[array[key]].nom !== undefined) &&
                    (!isEmpty(tmp[array[key]].prenom) || !isEmpty(tmp[array[key]].nom))
                ) {
                    tmpObj[array[key]] = tmp[array[key]];
                }
            }
            // console.log("SET VALIDATEURS 2");
            // setValidateurs(tmpObj);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [pt, loading]);

    const handleChangeClicked = async () => {
        await setClicked(true);
        handlePrint();
    };

    const handleChange = (e, id) => {
        let tmp = validateurs;
        if (e.target.checked) {
            tmp[id].signe = 2;
            tmp[id].numerique = 0;
            console.log("checked");
        } else {
            tmp[id].signe = 0;
            tmp[id].numerique = 0;
        }
        setValidateurs(tmp);
    };

    // partie checkbox pour chaque validateur

    return (
        <div>
            <div style={{ margin: "30px", textAlign: "center" }}>
                Veuillez choisir parmis la liste suivante les intervenants qui doivent signer le Permis de Travail.
            </div>
            <div className="CheckboxValidateur">
                {!loading &&
                    validateurs !== undefined &&
                    Object.entries(validateurs)
                        .filter(filterInvalidValidatorsCallback)
                        .map(([k, validateur]) => {
                            if (validateur.signe !== 1) {
                                return (
                                    <Checkbox
                                        label={`${validateur?.prenom} ${validateur?.nom}`}
                                        onChange={handleChange}
                                        id={k}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
            </div>

            {!loading && clicked && (
                <>
                    <ComponentToPrint ref={componentRef} pt={pt} eu={eu} validateurs={validateurs} />
                </>
            )}

            <button
                style={{ marginLeft: "15%" }}
                onClick={handleChangeClicked}
                className="modal_button_imprimer_validateur"
            >
                Imprimer la sélection
            </button>
        </div>
    );
};

const Checkbox = ({ label, value, onChange, id }) => {
    return (
        <label
            style={{
                marginBottom: "20px",
                marginLeft: "20px",
                display: "flex",
                alignItems: "center",
            }}
        >
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => onChange(e, id)}
                style={{ marginRight: "15px", width: "20px", height: "20px" }}
            />
            {label}
        </label>
    );
};

export default Example;
