import {GET_RISQUES} from "../actions/risques.actions";

const initialState = {};

export default function risquesReducer( state = initialState, action) {

    switch (action.type) {
        case GET_RISQUES:
            return action.payload;

        default:
            return state;
    }
}