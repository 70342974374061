import React, { useEffect, useState } from "react";
import "./RecommandationsAdmin.css";
import HeaderAdmin from "../HeaderAdmin/HeaderAdmin";
import NavbarAdmin from "../NavbarAdmin/NavbarAdmin";
import { useDispatch, useSelector } from "react-redux";
import {
    getRecommandations,
    setAvantRecommandation,
    setModeOpeRecommandation,
    setRecoOrgaSec,
} from "../../../Redux/actions/recommandation.actions";
import { isEmpty } from "../../../Utils/utils";
import CardRecoAdmin from "./CardRecoAdmin";
import { useSearchParams } from "react-router-dom";

function RecommandationsAdmin(props) {
    const userReducer = useSelector((state) => state.userReducer);
    const recommandationReducer = useSelector((state) => state.recommandationReducer);
    const [idUsine, setIdUsine] = useState("");
    const [loading, setLoading] = useState(true);
    const [orgaSec, setOrgaSec] = useState({});
    const [avant, setAvant] = useState("");
    const [modeOpe, setModeOpe] = useState("");
    const [showEditAvant, setShowEditAvant] = useState(false);
    const [showEditModeOpe, setShowEditModeOpe] = useState(false);
    const [showNewRule, setShowNewRule] = useState(false);
    const [titre, setTitre] = useState("");
    const [texte, setTexte] = useState("");

    const dispatch = useDispatch();

    const fetchData = async () => {
        await dispatch(getRecommandations());
        setLoading(false);
    };

    useEffect(() => {
        if (userReducer.id !== undefined) {
            if (userReducer.admin !== 1) {
                window.location = "/accueil";
            } else {
                if (loading && userReducer.id !== undefined) {
                    setIdUsine(userReducer.id_hash_usine);
                    fetchData();
                } else {
                    if (!isEmpty(recommandationReducer[0]))
                        recommandationReducer.map((reco) => {
                            if (reco.id_usine.toString() === idUsine) {
                                document.getElementById("avant_inter_admin").innerHTML = reco.avant_intervention;
                                document.getElementById("mode_ope_admin").innerHTML = reco.mode_operatoire;

                                setAvant(reco.avant_intervention);
                                setModeOpe(reco.mode_operatoire);
                                setOrgaSec(JSON.parse(reco.orga_secours));
                            }
                        });
                }
            }
        }
    }, [userReducer, loading, recommandationReducer]);

    const handleEditAvant = async () => {
        if (!showEditAvant) {
            setShowEditAvant(true);
        } else {
            await setShowEditAvant(false);
            await dispatch(setAvantRecommandation(idUsine, avant));
            document.getElementById("avant_inter_admin").innerHTML = avant;
        }
    };

    const handleEditModeOpe = async () => {
        if (!showEditModeOpe) {
            setShowEditModeOpe(true);
        } else {
            await setShowEditModeOpe(false);
            await dispatch(setModeOpeRecommandation(idUsine, modeOpe));
            document.getElementById("mode_ope_admin").innerHTML = modeOpe;
        }
    };

    const handleChangeOrgaSec = async (i, orga) => {
        let data = orgaSec;
        Object.keys(data).map((key, index) => {
            if (index === i) {
                data[key] = orga;
            }
        });

        await dispatch(setRecoOrgaSec(idUsine, data));
    };

    const handleDeleteOrga = async (i) => {
        let data = orgaSec;
        Object.keys(data).map((key, index) => {
            if (index === i) {
                delete data[key];
            }
        });

        await dispatch(setRecoOrgaSec(idUsine, data));
        setLoading(true);
        setLoading(false);
    };

    const handleAddNewOrga = async () => {
        if (!showNewRule) {
            setShowNewRule(!showNewRule);
        } else {
            let data = orgaSec;
            const length = Object.keys(data).length;
            console.log(length);
            let lastKey = "";
            Object.keys(data).map((key, i) => {
                if (i === length - 1) {
                    lastKey = key;
                }
            });
            let newKey = lastKey.replace("obj", "");
            newKey = parseInt(newKey);
            newKey = newKey + 1;
            newKey = "obj" + newKey;
            data[newKey] = {
                titre,
                text: texte,
            };
            console.log(data);
            await dispatch(setRecoOrgaSec(idUsine, data));
            
            setShowNewRule(!showNewRule);
        }
    };

    return (
        !loading && (
            <div>
                <NavbarAdmin />
                <HeaderAdmin titre="Recommandations EE" />

                <div
                    className="content_RecoA aside-menu-content under-header-content content-padding"
                    style={{ overflowY: "scroll" }}
                >
                    <div>
                        <div
                            style={{
                                marginBottom: "50px",
                                borderStyle: "solid",
                                borderColor: "grey",
                                borderWidth: "2px",
                                padding: "5px",
                                backgroundColor: "#D4D4D4",
                                borderRadius: "5px",
                            }}
                        >
                            <h3>
                                Pour modifier un texte il suffit de cliquer sur éditer, de faire la modification et de
                                valider, pour faire des sauts de ligne, veuillez utiliser cette balise {"<br>"}
                            </h3>
                        </div>
                        <h3>AVANT L'INTERVENTION</h3>
                        {!showEditAvant ? (
                            <p className="paragraphe_Reco" id="avant_inter_admin" style={{ marginBottom: "8px" }}></p>
                        ) : (
                            <textarea
                                defaultValue={avant}
                                onChange={(e) => setAvant(e.target.value)}
                                style={{ width: "100%", height: "25vh" }}
                            />
                        )}
                        <button onClick={handleEditAvant}>{showEditAvant ? <>Valider</> : <>Editer</>}</button>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <h3>MODE OPÉRATOIRE</h3>
                        {!showEditModeOpe ? (
                            <p className="paragraphe_Reco" id="mode_ope_admin" style={{ marginBottom: "8px" }}></p>
                        ) : (
                            <textarea
                                defaultValue={modeOpe}
                                onChange={(e) => setModeOpe(e.target.value)}
                                style={{ width: "100%", height: "25vh" }}
                            />
                        )}
                        <button onClick={handleEditModeOpe}>{showEditModeOpe ? <>Valider</> : <>Editer</>}</button>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <h3>ORGANISATION DES SECOURS</h3>
                        {Object.keys(orgaSec).map((key, i) => {
                            return (
                                <CardRecoAdmin
                                    text={orgaSec[key]}
                                    i={i}
                                    handleChange={handleChangeOrgaSec}
                                    handleDeleteOrga={handleDeleteOrga}
                                />
                            );
                        })}
                    </div>
                    <div style={{ marginLeft: "1%" }}>
                        {showNewRule && (
                            <>
                                <div className="st_recommandation">
                                    <textarea
                                        placeholder="titre"
                                        style={{ width: "100%", height: "5vh" }}
                                        onChange={(e) => setTitre(e.target.value)}
                                    />
                                </div>
                                <textarea
                                    placeholder="texte"
                                    style={{ width: "100%", height: "10vh" }}
                                    onChange={(e) => setTexte(e.target.value)}
                                />
                            </>
                        )}
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <button style={{ marginLeft: "1%" }} onClick={handleAddNewOrga}>
                            Ajouter une règle d'organisation
                        </button>
                        {showNewRule && (
                            <button onClick={() => setShowNewRule(!showNewRule)} style={{ marginLeft: "1%" }}>
                                Annuler
                            </button>
                        )}
                    </div>
                </div>
            </div>
        )
    );
}

export default RecommandationsAdmin;
