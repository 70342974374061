import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";

// Using a class component, everything works without issue
export class CardRecoPDF extends React.PureComponent {
    render() {
        return (
            <>
                <p style={{ fontSize: "1.2em" }}>{this.props.orga_sec.titre}</p>
                <p dangerouslySetInnerHTML={{ __html: this.props.orga_sec.text }}></p>
                <br />
            </>
        );
    }
}
