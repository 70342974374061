import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import ExampleValid from "../../../../PDF/PDFValidateurs/PDFValidateursDocusign";
import ExampleFin from "../../../../PDF/PDFValidateurs/PDFSignatairesFinDocusign";
import SignatureModal from "../../../../SignatureModal/SignatureModal";
import { addSignatureEnd, addSignatureValidation } from "../../../../../../Utils/api/signature.api";

function CardVSNPT({ valid, pt, fin, validKey, onSign }) {
    const dispatch = useDispatch();

    const [showSign, setShowSign] = useState(false);

    const handleSign = async () => {
        if (valid.mail === undefined) {
            alert("Le validateur n'a pas de mail renseigné");
        } else {
            setShowSign(!showSign);
        }
    };

    const handleValidation = async (signatureFile) => {
        try {
            const newValidators = await addSignatureValidation(
                pt.id,
                signatureFile,
                valid,
                validKey,
                pt.id_usine,
                pt.numero_PT
            );
            setShowSign(false);
            onSign(newValidators);
        } catch (error) {
            console.log("Error on sign validation request", error);
        }
    };

    const handleEndValidate = async (signatureFile) => {
        try {
            const newValidators = await addSignatureEnd(
                pt.id,
                signatureFile,
                valid,
                validKey,
                pt.id_usine,
                pt.numero_PT
            );
            setShowSign(false);
            onSign(newValidators);
        } catch (error) {
            console.log("Error on sign end request", error);
        }
    };

    const handleSignValidate = (signatureFile) => {
        if (fin) {
            handleEndValidate(signatureFile);
        } else {
            handleValidation(signatureFile);
        }
    };

    return (
        <>
            <div className="box_vnspt">
                <div className="box_vnspt_header">
                    {valid.prenom} {valid.nom}
                </div>
                <div className="box_vnspt_content">
                    <br />
                    {valid.entreprise}
                </div>
                <div className="box_vnspt_button">
                    <button type="button" onClick={handleSign}>
                        <FontAwesomeIcon icon={faSignature} />
                        Signer le VISA
                    </button>
                </div>
            </div>
            <SignatureModal
                isOpen={showSign}
                onClose={() => {
                    setShowSign(false);
                }}
                title={fin ? "Signature de fin de chantier" : "Signature de validation"}
                validator={valid}
                onValidate={handleSignValidate}
            />
            {/* {showSign ? fin ?
                <ExampleFin pt={pt} valid={valid}/>
                :
                <ExampleValid pt={pt} valid={valid}/>
                :
                null
            } */}
        </>
    );
}
export default CardVSNPT;
