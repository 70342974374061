import React from "react";
import "./Aide.css";
import HeaderReglage from "../HeaderReglage/HeaderReglage";
import NavbarReglage from "../NavbarReglage/NavbarReglage";

function Aide(props) {
    return (
        <div>
            <HeaderReglage titre="Aide" />
            <NavbarReglage />

            <div className="aide-content aside-menu-content under-header-content content-padding">
                <h1>Manuel utilisateur</h1>
                <a
                    href={`${process.env.REACT_APP_API_URL}/manuel`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-success btn-download"
                >
                    Télécharger le manuel
                </a>
            </div>
        </div>
    );
}

export default Aide;
