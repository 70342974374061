import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";
import { BoutonARPDF } from "./BoutonARPDF";
import { isEmpty } from "../../../../../Utils/utils";

// Using a class component, everything works without issue
export class AnalyseRisquePDF extends React.PureComponent {
    state = {
        keyRisque: Object.keys(this.props.risques),
        risqueModeOpeCom: JSON.parse(this.props.pt.risque_mode_ope_com),
    };

    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">III - ANALYSE DE RISQUE</h1>

                    <div className="DescriptionPDFContent" style={{ marginTop: "10px" }}>
                        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
                            <div style={{ marginBottom: "10px" }}>
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={
                                        !isEmpty(this.state.risqueModeOpeCom)
                                            ? this.state.risqueModeOpeCom.mode_ope
                                            : false
                                    }
                                />
                                <label htmlFor="checkbox">Mode opératoire fourni par l'EE</label>
                                <br />
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={
                                        !isEmpty(this.state.risqueModeOpeCom)
                                            ? this.state.risqueModeOpeCom.acte_valid
                                            : false
                                    }
                                />
                                <label htmlFor="checkbox">Acte de sous-traitance validé</label>
                                <br />
                                <input
                                    className="form-check-input"
                                    style={{ marginRight: "20px" }}
                                    id="checkbox"
                                    name="modeOperatoireEE"
                                    type="checkbox"
                                    defaultChecked={
                                        !isEmpty(this.state.risqueModeOpeCom)
                                            ? this.state.risqueModeOpeCom.veille_ely
                                            : false
                                    }
                                />
                                <label htmlFor="checkbox">
                                    Veille réglementaire effectuée sur la plateforme ELYFEC
                                </label>
                            </div>
                            <table className="table table-bordered table-sm align-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">DOMAINE / Risque</th>
                                        <th scope="col">Mesure de prévention</th>
                                        <th scope="col">EU</th>
                                        <th scope="col">EE/ES</th>
                                    </tr>
                                </thead>
                                <tbody style={{ margin: 50 }}>
                                    {this.props.risques !== null &&
                                        this.props.risques !== undefined &&
                                        Object.values(this.props.risques)
                                            // .filter((risque) => {
                                            //     const mesures = JSON.parse(risque.mesures_prevention);
                                            //     console.log(mesures);
                                            //     return (
                                            //         !isEmpty(risque.mesures) &&
                                            //         (mesures.obj1?.eu === "1" || mesures.obj1?.ee === "1")
                                            //     );
                                            // })
                                            .map((risque) => {
                                                return (
                                                    <BoutonARPDF
                                                        key={risque.titre_risque}
                                                        titre={risque.titre_risque}
                                                        risque={risque}
                                                    />
                                                );
                                            })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <p style={{ width: "80%", margin: "0 auto", fontSize: "1.2rem", textAlign: "center" }}>
                    {this.props.modOpComInfos.commentaire}
                </p>
                <div className="sectionSpacing"></div>
            </div>
        );
    }
}
