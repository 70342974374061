import React, { useState } from "react";
import "../Actions/BoutonsValidation.css";

function DepartI(props) {
    const [modal, setModal] = useState(false);

    const toggleModalQRCode = () => { 
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }

    const [hours, setHours] = useState(new Date().getHours());             
    const [minutes, setMinutes] = useState(new Date().getMinutes()); 

    var hoursFormated = hours;
    if (hoursFormated < 10) {
        hoursFormated = "0" + hoursFormated;
    }
    var minutesFormated = minutes;
    if (minutesFormated < 10) {
        minutesFormated = "0" + minutesFormated;
    }
    
    return(
        <div>
            <button onClick={toggleModalQRCode} className="boutonIntervenant">
                Leaving
            </button>


            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Leaving
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalQRCode}
                                >
                                    X
                                </button>
                            </div>
                            <div>
                                <div className="infoValidateur">
                                    <p style={{paddingBottom: '20px'}}>Fisrtname</p>
                                    <p style={{paddingBottom: '20px'}}>Lastname</p>
                                    <p style={{paddingBottom: '30px'}}>Date</p>
                                </div>
                                

                                <div style={{paddingBottom: '30px'}}>
                                    
                                    <h4>WORKING HOURS</h4>
                                    <div className="d-flex align-items-center">
                                        <input value={hoursFormated} type="text" className="inputDepart" disabled style={{marginBottom: '10px'}}/>
                                        <label style={{marginLeft: '20px'}}>Hour</label>
                                    </div>

                                    <div  className="d-flex align-items-center">
                                        <input className="inputDepart" value={minutesFormated} disabled />
                                        <label style={{marginLeft: '20px'}}>Minutes</label>
                                    </div>
                                </div>
                                <div style={{paddingBottom: '30px'}}>
                                    <h4>BREAK TIME</h4>
                                    <div className="d-flex align-items-center">
                                        <input placeholder="" type="text" className="inputDepart" style={{marginBottom: '10px'}}/>
                                        <label style={{marginLeft: '20px'}}>Hour</label>
                                    </div>

                                    <div  className="d-flex align-items-center">
                                        <input className="inputDepart" placeholder="" />
                                        <label style={{marginLeft: '20px'}}>Minutes</label>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="modal_button_imprimer_validateur"
                                >
                                    Validate
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            )}
        </div>
    )
}

export default DepartI;