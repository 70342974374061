import React from "react";
import "./ComponentToPrint.css";
import { Sommaire }  from "./Sommaire/Sommaire";
import { DescriptionPDF } from "./Description/DescriptionPDF";
import { RecommandationsPDF } from "./Recommandations/Recommandations";
import { AnalyseRisquePDF } from "./AnalyseRisque/AnalyseRisque";
import { ValidationPDF } from "./Validation/Validation";
import { FinChantierPDF } from "./FinChantier/FinChantier";

// Using a class component, everything works without issue
export class ComponentToPrint extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent PDFContentPT">
                <Sommaire id={this.props.id} pt={this.props.pt} eu={this.props.eu} />

                <DescriptionPDF
                    id={this.props.id}
                    ee={this.props.ee}
                    eu={this.props.eu}
                    eeCon={this.props.eeCon}
                    st={this.props.st}
                    stCon={this.props.stCon}
                    pt={this.props.pt}
                />

                <RecommandationsPDF
                    id={this.props.id}
                    pt={this.props.pt}
                    orga={this.props.orga}
                    eu={this.props.eu}
                    reco={this.props.reco}
                    orga_sec={this.props.orga_sec}
                    firstKeys={this.props.firstKeys}
                    secondKeys={this.props.secondKeys}
                />

                <AnalyseRisquePDF
                    id={this.props.id}
                    risques={this.props.risques}
                    titreRisque={this.props.theRisqueTitre}
                    modOpComInfos={this.props.modOpComInfos}
                    pt={this.props.pt}
                    eu={this.props.eu}
                />

                <ValidationPDF
                    id={this.props.id}
                    pt={this.props.pt}
                    eu={this.props.eu}
                    validateur={this.props.validateur}
                />
                <FinChantierPDF
                    id={this.props.id}
                    pt={this.props.pt}
                    eu={this.props.eu}
                    avisChantier={this.props.avisChantier}
                    commentaires={this.props.commentaires}
                    signatairesFin={this.props.signatairesFin}
                />
            </div>
        );
    }
}
