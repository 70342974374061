import React, { useEffect, useState } from "react";
import LeftLogin from "./DesignLogin/LeftLogin";
import ModifierDate from "../PT/NewPT/Intervenants/Boutons/ModifierDate/ModifierDate";
import ArriveeI from "../PT/NewPT/Intervenants/Boutons/Actions/Arrivee";
import DepartI from "../PT/NewPT/Intervenants/Boutons/Actions/Depart";
import Pointage from "../PT/NewPT/Intervenants/Boutons/Actions/Pointage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../Utils/utils";
import { getIntervenant } from "../../Redux/actions/intervenant.actions";
import axios from "axios";

function PointageEE(props) {
    const [date, setDate] = useState(
        new Date().toLocaleString("fr-FR", { weekday: "short", day: "2-digit", month: "long", year: "numeric" })
    );
    const [pointage, setPointage] = useState(null);
    const [keyOfDate, setKeyOfDate] = useState(null);

    const [horaireArrivee, setHoraireArriver] = useState("");
    const [horaireDepart, setHoraireDepart] = useState("");
    const [tempsPause, setTempsPause] = useState("");
    const [showUpdateDate, setShowUpdateDate] = useState(false);

    const setShowDate = () => {
        setShowUpdateDate(!showUpdateDate);
    };
    const handleChangeDate = (date) => {
        setDate(date.toLocaleString("default", { weekday: "short", day: "2-digit", month: "long", year: "numeric" }));
    };
    const dispatch = useDispatch();

    const intervenantReducer = useSelector((state) => state.intervenantReducer);

    const { pt } = useParams();
    const { contact } = useParams();

    const [intervenant, setIntervenant] = useState({});

    const fetchIntervenants = async () => {
        await dispatch(getIntervenant());
    };

    useEffect(() => {
        if (isEmpty(intervenantReducer[0])) {
            fetchIntervenants();
        } else {
            intervenantReducer.map((intervenant) => {
                if (
                    intervenant.id_PT.toString() === pt.toString() &&
                    intervenant.id_contact.toString() === contact.toString()
                ) {
                    setIntervenant(intervenant);
                    if (intervenant.pointage_jour !== null) {
                        setPointage(JSON.parse(intervenant.pointage_jour));
                    }
                }
            });
            if (pointage !== null) {
                for (const key in pointage) {
                    let datePointage = pointage[key].jour;

                    if (datePointage === date.toString()) {
                        setKeyOfDate(key);
                    }
                }
                if (!isEmpty(Object.entries(pointage))) {
                    if (keyOfDate === null || date !== pointage[keyOfDate].jour) {
                        for (const key in pointage) {
                            let datePointage = pointage[key].jour;

                            if (datePointage === date) {
                                setKeyOfDate(key);
                                break;
                            } else {
                                setKeyOfDate(null);
                            }
                        }
                    }
                }
            }
        }
        if (pointage !== null) {
            if (keyOfDate !== null) {
                if (pointage[keyOfDate].arrivee !== undefined) {
                    setHoraireArriver(pointage[keyOfDate].arrivee);
                }
                if (pointage[keyOfDate].depart !== undefined) {
                    setHoraireDepart(pointage[keyOfDate].depart);
                }
                if (pointage[keyOfDate].pause !== undefined) {
                    setTempsPause(pointage[keyOfDate].pause);
                }
            }
        } else {
            setHoraireArriver("");
            setHoraireDepart("");
            setTempsPause("");
        }
    }, [intervenantReducer, date, keyOfDate]);

    const handleSubmit = () => {
        const arrivee = new Date("01/01/1970 " + horaireArrivee + ":00");
        const pause = new Date("01/01/1970 " + tempsPause + ":00");
        const depart = new Date("01/01/1970 " + horaireDepart + ":00");

        const tot = depart - arrivee - pause;
        let diff = {};

        let tmp = Math.floor(tot / 1000);
        tmp = Math.floor(tmp / 60);
        diff.min = tmp % 60;
        console.log(diff);
        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = (tmp % 24) - 1;

        let totFinal = "0" + diff.hour + ":" + diff.min;
        if (diff.min === 0) {
            totFinal = totFinal + "0";
        }

        if (totFinal.includes("NaN")) {
            totFinal = "";
        }

        const datas = {
            jour: date,
            arrivee: horaireArrivee,
            depart: horaireDepart,
            pause: tempsPause,
            total: totFinal,
        };
        handleUpdatePointage(datas, intervenant);
    };

    const handleUpdatePointage = async (data, intervenant) => {
        const pointages = JSON.parse(intervenant.pointage_jour);
        let keyOfDay = "";
        if (pointages !== null) {
            for (const [key, value] of Object.entries(pointages)) {
                if (value.jour === data.jour) {
                    keyOfDay = key;
                }
            }
        }
        if (keyOfDay !== "") {
            pointages[keyOfDay] = data;
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${pt}/${intervenant.id_contact}`,
                data: pointages,
                withCredentials: true,
            })
                .then((res) => {
                    if (res) {
                        alert("Pointage mis à jour");
                        window.location = window.location;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("Erreur dans la sauvegarde du pointage");
                });
        } else {
            let name = "pointage1";
            if (pointages !== null) {
                const length = Object.keys(pointages).length + 1;
                name = "pointage" + length;
                pointages[name] = data;

                await axios({
                    method: "put",
                    url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${pt}/${intervenant.id_contact}`,
                    data: pointages,
                    withCredentials: true,
                })
                    .then((res) => {
                        if (res) {
                            alert("Pointage mis à jour");
                            window.location = window.location;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Erreur dans la sauvegarde du pointage");
                    });
            } else {
                let object = {};
                object[name] = data;

                await axios({
                    method: "put",
                    url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${pt}/${intervenant.id_contact}`,
                    data: object,
                    withCredentials: true,
                })
                    .then((res) => {
                        if (res) {
                            alert("Pointage mis à jour");
                            window.location = window.location;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Erreur dans la sauvegarde du pointage");
                    });
            }
        }
    };

    return (
        <div className="d-flex">
            <LeftLogin className="desktop-only" />
            <div
                style={{
                    flex: 1,
                    backgroundColor: "#072872",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    padding: "20px 4px",
                }}
            >
                {/* <select>
                    <option>Français</option>
                    <option>English</option>
                </select> */}
                <div className="d-flex m-column" style={{ marginBottom: "50px", alignItems: "center" }}>
                    <h2 style={{ marginTop: "16px", marginRight: "20px"}}>{date}</h2>
                    <ModifierDate handleChangeDate={handleChangeDate} showUpdateDate={setShowDate} />
                </div>
                <div style={{ padding: "12px" }}>
                    <h3 style={{ marginBottom: "40px" }}>POINTAGE DU JOUR</h3>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ backgroundColor: "#536a9c", height: "60px", marginBottom: "20px", padding: "20px" }}
                    >
                        <p>Pointage d'arrivée</p>
                        {keyOfDate !== null ? (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={pointage ? pointage[keyOfDate].arrivee : ""}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setHoraireArriver(e.target.value)}
                            />
                        ) : (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={null}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setHoraireArriver(e.target.value)}
                            />
                        )}
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ backgroundColor: "#536a9c", height: "60px", marginBottom: "20px", padding: "20px" }}
                    >
                        <p>Pointage de départ</p>
                        {keyOfDate !== null ? (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={pointage ? pointage[keyOfDate].depart : ""}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setHoraireDepart(e.target.value)}
                            />
                        ) : (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={null}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setHoraireDepart(e.target.value)}
                            />
                        )}
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ backgroundColor: "#536a9c", height: "60px", marginBottom: "20px", padding: "20px" }}
                    >
                        <p>Temps de pause</p>
                        {keyOfDate !== null ? (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={pointage ? pointage[keyOfDate].pause : ""}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setTempsPause(e.target.value)}
                            />
                        ) : (
                            <input
                                type="time"
                                min="06:00"
                                max="19:00"
                                defaultValue={null}
                                className="inputDepart"
                                style={{ marginBottom: "4px" }}
                                aria-colcount={15}
                                onChange={(e) => setTempsPause(e.target.value)}
                            />
                        )}
                    </div>
                </div>
                <button
                    style={{
                        backgroundColor: "#536a9c",
                        border: "none",
                        height: "40px",
                        color: "white",
                        padding: "0 20px",
                    }}
                    onClick={handleSubmit}
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    );
}

export default PointageEE;
