import React from "react";
import "./HeaderNewPT.css";

function Header(props) {
        return(
            <div>
                <div className="header_newpt">
                    <div className="titre_page_newpt">
                        <h1>{props.titre}</h1>
                    </div>
                </div>
                
            </div>
        
        );
};

export default Header;