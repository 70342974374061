import React, { useState, useEffect, useMemo, useCallback } from "react";
import Navbar from "../NavBar/Navbar";
import Header from "../Header/Header";
import "../TablePT.css";
import { contains, isEmpty, sortPTByCreatedDate, sortPTByDate } from "../../../Utils/utils";
import CardPt from "../CardPt";
import { useDispatch, useSelector } from "react-redux";
import { getInvolvedPt, getPt } from "../../../Redux/actions/pt.actions";
import { getFavoris } from "../../../Redux/actions/favoris.actions";
import EnvoiMailPT from "../EnvoiMailPT";

function Clotures() {
    // all datas from PT are set in "pts"
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.userReducer);
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const favorisReducer = useSelector((state) => state.favorisReducer);

    const [favUser, setFavUser] = useState([]);

    const [numFiltre, setNumFiltre] = useState("");
    const [titreFiltre, setTitreFiltre] = useState("");
    const [eeFiltre, setEEFiltre] = useState("");
    const [dateDebFiltre, setDateDebFiltre] = useState("");
    const [dateFinFiltre, setDateFinFiltre] = useState("");
    const [rdpFiltre, setRdpFiltre] = useState("");
    const [showSend, setShowSend] = useState(false);
    const [ptEnvoi, setPtEnvoi] = useState({});

    const isAdmin = useMemo(() => {
        return userReducer && userReducer.admin === 1;
    }, [userReducer]);

    const showSendMail = (pt) => {
        setPtEnvoi(pt);
        setShowSend(!showSend);
    };

    const handleChangeNumFiltre = (numF) => {
        setNumFiltre(numF);
    };

    const handleChangeTitreFiltre = (titreF) => {
        setTitreFiltre(titreF);
    };

    const handleChangeEEFiltre = (eeF) => {
        setEEFiltre(eeF);
    };

    const handleChangeDateDebFiltre = (dateDebF) => {
        setDateDebFiltre(dateDebF);
    };

    const handleChangeDateFinFiltre = (dateFinF) => {
        setDateFinFiltre(dateFinF);
    };

    const handleChangeRdpFiltre = (rdpF) => {
        setRdpFiltre(rdpF);
    };

    const fetchPt = useCallback(async () => {
        const id_usine = userReducer.id_hash_usine;
        if (isAdmin) {
            await dispatch(getPt(id_usine));
        } else {
            await dispatch(getInvolvedPt(id_usine));
        }
        await dispatch(getFavoris());
    }, [dispatch, isAdmin, userReducer.id_hash_usine]);

    const setFavs = useCallback(async () => {
        let favs = "";
        if (!isEmpty(favorisReducer[0])) {
            await favorisReducer.map((user) => {
                if (user.id_user === userReducer.id) {
                    favs = user.favoris_pt;
                }
            });
        }
        favs = favs.slice(1);
        favs = favs.slice(0, -1);
        favs = favs.replace(/^\D+/g, "");
        favs = favs.replace(/,/g, "");
        favs = favs.trim();
        let array = [];
        for (let i = 0; i < favs.length; i++) {
            array.push(favs[i]);
        }
        setFavUser(array);
    }, [favorisReducer, userReducer.id]);

    useEffect(() => {
        if (userReducer.id_hash_usine !== undefined) {
            fetchPt();
            setLoading(false);
        }
    }, [userReducer, fetchPt]);

    useEffect(() => {
        if (!loading) {
            setFavs();
        }
    }, [setFavs, loading]);

    const cancel = () => {
        setShowSend(!showSend);
    };

    return (
        <div>
            {showSend && (
                <EnvoiMailPT
                    pt={ptEnvoi}
                    show={cancel}
                    onClose={() => {
                        setShowSend(false);
                    }}
                />
            )}
            <Header
                titre="Cloturés"
                handleChangeNum={handleChangeNumFiltre}
                handleChangeTitre={handleChangeTitreFiltre}
                handleChangeEE={handleChangeEEFiltre}
                handleChangeDateDeb={handleChangeDateDebFiltre}
                handleChangeDateFin={handleChangeDateFinFiltre}
                handleChangeRdp={handleChangeRdpFiltre}
            />
            <Navbar />
            <div className="tableau_pt">
                <table className="table table-borderless" style={{ color: "grey", border: "none" }}>
                    <thead>
                        <tr className="tableau_header">
                            <th>FAV</th>
                            <td id="numeroPT">N°</td>
                            <td id="titrePT">
                                TITRE PT <br />
                                NOM EE
                            </td>
                            <td id="datePT">
                                DÉBUT <br /> FIN
                            </td>
                            <td id="DOPT">Rédacteur du permis</td>
                            <td>OPTIONS</td>
                        </tr>
                    </thead>
                </table>

                {loading ? (
                    <div>Loading ...</div>
                ) : (
                    <div className="pt-contents">
                        {!isEmpty(ptReducer[0]) ? (
                            <>
                                {ptReducer[0].sort(sortPTByCreatedDate).map((pt) => {
                                    if (pt.status === "clotures") {
                                        const eePt = JSON.parse(pt.entreprise_exterieure_PT);
                                        const euPt = JSON.parse(pt.entreprise_utilisatrice_PT);
                                        let nomEE = "";
                                        let dateDebEE = "";
                                        let dateFinEE = "";
                                        if (eePt.nom_EE_PT !== undefined) {
                                            nomEE = eePt.nom_EE_PT;
                                        }
                                        if (eePt.date_debut_operations_EE !== undefined) {
                                            dateDebEE = eePt.date_debut_operations_EE;
                                        }
                                        if (eePt.date_fin_operations_EE !== undefined) {
                                            dateFinEE = eePt.date_fin_operations_EE;
                                        }

                                        if (
                                            contains(pt.numero_PT, numFiltre) &&
                                            contains(pt.nom_PT, titreFiltre) &&
                                            contains(nomEE, eeFiltre) &&
                                            contains(dateDebEE, dateDebFiltre) &&
                                            contains(dateFinEE, dateFinFiltre) &&
                                            contains(euPt.contact_RdP.prenomRdP, rdpFiltre)
                                        ) {
                                            return <CardPt pt={pt} send={showSendMail} />;
                                        }
                                    } else {
                                        return null;
                                    }
                                })}
                            </>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Clotures;
